import { fieldTypes } from '../../framework/formBuilder/utils';

export const VALIDATION_REGEX = {
  // PHONE: /^[\+]?[(]?[0-9]{1,3}[)]?[-\s\.]?[0-9]{1,3}[-\s\.]?[0-9]{1,7}$/im,
  // PHONE: /^\(?([0-9]{3})\)?[- ]?([0-9]{3})[- ]?([0-9]{4})$/,
  // PHONE: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
  // PHONE: /^(?=.*?[1-9])[+ 0-9 ()-]+$/,
  PHONE: /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#| ext\.? )[\-\.\ \\\/]?(\d+))?$/i,
  EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  WEBSITE: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  LICENSE: /^.{1,10}$/,
  NPINUMBER: /^\d{10}$/,
  EXTENDEDLICENSE: /^.{1,40}$/,
  ALPHABETS: /^[a-zA-Z][a-zA-Z ]*$/,
  LATLONG: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
};

export const MULTI_PAGE_VIEW_WARNING =
  'You clicked “Multi page view”. Are you sure you want to continue?';

export const MODAL_TYPES = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  ERROR_LIST: 'ERROR_LIST',
  WARNING: 'WARNING',
  INFO: 'INFO',
  INFO_LIST: 'INFO_LIST',
  EXPANDING_ERROR_LIST: 'EXPANDING_ERROR_LIST',
  CUSTOM_INFO_LIST: 'CUSTOM_INFO_LIST',
};

export const ALERT_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const ALERT_MESSAGES = {
  CHILD_EDIT: {
    isOpen: true,
    message: 'Pharmaprojects Child Drug Record edited successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  CHILD_SAVE: {
    isOpen: true,
    message: 'Pharmaprojects Child drug record created successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  DRUGS_SAVE: {
    isOpen: true,
    // message: 'Drug record draft saved successfully, click PUBLISH to publish Drug record changes',
    message: 'Page Successfully saved',
    alertType: ALERT_TYPES.SUCCESS,
  },
  DRUGS_DELETE: {
    isOpen: true,
    message: 'Drug Record Deleted Successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  DRUGS_ERROR: {
    isOpen: true,
    message: 'There was a problem with the server.',
    alertType: ALERT_TYPES.ERROR,
  },
  TRIALS_SAVE: {
    isOpen: true,
    // message: 'Trial record draft saved successfully, click PUBLISH to publish Trial record changes',
    message: 'Page Successfully saved',
    alertType: ALERT_TYPES.SUCCESS,
  },
  TRIALS_RESULTS_SAVE: {
    isOpen: true,
    message: 'Trial Result(s) successfully saved as draft',
    alertType: ALERT_TYPES.SUCCESS,
  },
  TRIALS_NOTES_SAVE: {
    isOpen: true,
    message: 'Trial Note(s) successfully saved as draft',
    alertType: ALERT_TYPES.SUCCESS,
  },
  TRIALS_DELETE: {
    isOpen: true,
    message: 'Trial Record Deleted Successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  TRIALS_ERROR: {
    isOpen: true,
    message: 'There was a problem with the server.',
    alertType: ALERT_TYPES.ERROR,
  },
  ORGANIZATION_SAVE: {
    isOpen: true,
    // message:
    //   'Organization record draft saved successfully, click PUBLISH to publish Organization record changes',
    message: 'Page Successfully saved',
    alertType: ALERT_TYPES.SUCCESS,
  },
  ORGANIZATION_DELETE: {
    isOpen: true,
    message: 'Organization Record Deleted Successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  ORGANIZATION_ERROR: {
    isOpen: true,
    message: 'There was a problem with the server.',
    alertType: ALERT_TYPES.ERROR,
  },
  PERSONS_CREATE: {
    isOpen: true,
    message: 'Person record created successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  PERSONS_SAVE: {
    isOpen: true,
    // message:
    //   'Person record draft saved successfully, click PUBLISH to publish Person record changes',
    message: 'Page Successfully saved',
    alertType: ALERT_TYPES.SUCCESS,
  },
  PERSONS_DELETE: {
    isOpen: true,
    message: 'Person Record deleted successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  PERSONS_ERROR: {
    isOpen: true,
    message: 'There was a problem with the server.',
    alertType: ALERT_TYPES.ERROR,
  },
  COMPANY_SAVE: {
    isOpen: true,
    // message:
    //   'Company record draft saved successfully, click PUBLISH to publish Company record changes',
    message: 'Page Successfully saved',
    alertType: ALERT_TYPES.SUCCESS,
  },
  COMPANY_DELETE: {
    isOpen: true,
    message: 'Company Record Deleted Successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  COMPANY_ERROR: {
    isOpen: true,
    message: 'There was a problem with the server.',
    alertType: ALERT_TYPES.ERROR,
  },
  PROCESSOR_SAVE: {
    isOpen: true,
    message: 'User Company saved successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  PROCESSOR_UPDATE: {
    isOpen: true,
    message: 'User Company updated successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  PROCESSOR_DELETE: {
    isOpen: true,
    message: 'User Company deleted successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  PROCESSOR_ERROR: {
    isOpen: true,
    message: 'There was a problem with the server.',
    alertType: ALERT_TYPES.ERROR,
  },
  PROCESSOR_DUPLICATE: {
    isOpen: true,
    message: 'User Company already exists',
    alertType: ALERT_TYPES.ERROR,
  },
  PROD_STATUS_SAVE: {
    isOpen: true,
    message: 'Production status has been successfully changed',
    alertType: ALERT_TYPES.SUCCESS,
  },
  PROD_STATUS_VALIDATE: {
    isOpen: true,
    message: 'Record saved and validated',
    alertType: ALERT_TYPES.SUCCESS,
  },
  PROD_STATUS_PENDING_REVIEW_SAVE: {
    isOpen: true,
    message: '"Pending Review” status has been successfully updated',
    alertType: ALERT_TYPES.SUCCESS,
  },
  PROD_STATUS_IN_PRODUCTION_SAVE: {
    isOpen: true,
    message: '"In Production” status has been successfully updated. Record is now visible on FOS',
    alertType: ALERT_TYPES.SUCCESS,
  },
  DRUGS_SMILES_ERROR: {
    isOpen: true,
    message: 'Please enter the valid SMILES formula.',
    modalType: MODAL_TYPES.ERROR,
  },
  DRUGS_CHEMICAL_NAME_ERROR: {
    isOpen: true,
    message: 'Please enter the valid chemical name.',
    modalType: MODAL_TYPES.ERROR,
  },
  DRUGS_EMPTY_STRUCTURE: {
    isOpen: true,
    message: 'Chemical structure image not exists.',
    modalType: MODAL_TYPES.ERROR,
  },
  DRUGS_CHEM_PARAMS_SUCCESS: {
    isOpen: true,
    message: 'Fields have been auto-populated successfully.',
    modalType: MODAL_TYPES.SUCCESS,
  },
  DRUGS_CHEM_PARAMS_ERROR: {
    isOpen: true,
    message: 'Field could not be auto-populated. Please check again',
    modalType: MODAL_TYPES.ERROR,
  },
  PROD_STATUS_OUT_OF_PRODUCTION_SAVE: {
    isOpen: true,
    message: 'Record saved successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  ADVANCED_SEARCH_ERROR: {
    isOpen: true,
    message: 'There was a problem with the server.',
    alertType: ALERT_TYPES.ERROR,
  },
  NOTES_SEARCH_ERROR: {
    isOpen: true,
    message: 'There are some unsaved changes. Please save it to proceed further.',
    alertType: ALERT_TYPES.ERROR,
  },
  NOTES_SAVE_ERROR: {
    isOpen: true,
    message: 'No changes were made to save.',
    alertType: ALERT_TYPES.ERROR,
  },
  SOURCEDOCUMENT_DELETE: {
    isOpen: true,
    message: 'Source Document deleted successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  SOURCEDOCUMENT_ERROR: {
    isOpen: true,
    message: 'There was a problem with the server.',
    alertType: ALERT_TYPES.ERROR,
  },
  SOURCEDOCUMENT_SAVE: {
    isOpen: true,
    message: 'Source document saved successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  MULTI_SOURCEDOCUMENT_SAVE: {
    isOpen: true,
    message: 'Source documents saved successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  SOURCEDOCUMENT_VALIDATE: {
    isOpen: true,
    message: 'Source document details validated successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  SOURCEDOCUMENT_PUBLISHED: {
    isOpen: true,
    message: 'Records has been published successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  SOURCEDOCUMENT_OUTOFSCOPE: {
    isOpen: true,
    message: 'Records has been moved to out of scope successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  REGISTRY_DELETE: {
    isOpen: true,
    message: 'Registry record deleted successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  REGISTRY_ERROR: {
    isOpen: true,
    message: 'There was a problem with the server.',
    alertType: ALERT_TYPES.ERROR,
  },
  REGISTRY_SAVE: {
    isOpen: true,
    message: 'Registry saved successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  REGISTRY_VALIDATE: {
    isOpen: true,
    message: 'Registry details validated successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  REGISTRY_PUBLISHED: {
    isOpen: true,
    message: 'Records has been published successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  COUNTRY_DELETE: {
    isOpen: true,
    message: 'Country record deleted successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  COUNTRY_ERROR: {
    isOpen: true,
    message: 'There was a problem with the server.',
    alertType: ALERT_TYPES.ERROR,
  },
  COUNTRY_SAVE: {
    isOpen: true,
    message: 'Country details saved successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  COUNTRY_VALIDATE: {
    isOpen: true,
    message: 'Country details validated successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  COUNTRY_PUBLISHED: {
    isOpen: true,
    message: 'Records has been published successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  COUNTRY_OUTOFSCOPE: {
    isOpen: true,
    message: 'Records has been moved to out of scope successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  REQUIREMENT_DELETE: {
    isOpen: true,
    message: 'Requirement record deleted successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  REQUIREMENT_ERROR: {
    isOpen: true,
    message: 'There was a problem with the server.',
    alertType: ALERT_TYPES.ERROR,
  },
  REQUIREMENT_SAVE: {
    isOpen: true,
    message: 'Requirement details saved successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  REQUIREMENT_OUTOFSCOPE: {
    isOpen: true,
    message: 'Records has been moved to out of scope successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  REQUIREMENT_PUBLISHED: {
    isOpen: true,
    message: 'Records has been published successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  REQUIREMENT_VALIDATE: {
    isOpen: true,
    message: 'Requirement details validated successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
  RECORDS_PUBLISHED: {
    isOpen: true,
    message: 'Records has been published successfully',
    alertType: ALERT_TYPES.SUCCESS,
  },
};

export const TABLE_FILTER_OPTIONS = [
  { value: 10, label: '10 Results' },
  { value: 25, label: '25 Results' },
  { value: 50, label: '50 Results' },
  { value: 100, label: '100 Results' },
  { value: 250, label: '250 Results' },
  { value: 500, label: '500 Results' },
  // { value: 1000, label: '1000 Results' },
  { value: 1000, label: '1000 Results' },
];

export const TABLE_FILTER_OPTIONS_SEARCH = [
  { value: 10, label: '10 Results' },
  { value: 25, label: '25 Results' },
  { value: 50, label: '50 Results' },
  { value: 100, label: '100 Results' },
  { value: 500, label: '500 Results' },
  { value: 1000, label: '1000 Results' },
];

export const treeViewConstants = {
  sideBar: 'sidebar',
  hierarchy: 'hierarchy',
};

export const bellIconSideBar = ['due today'];
export const triangleIconSideBar = ['overdue', 'backlog'];

export const MODAL_MESSAGES = {
  INGESTION_SUBMIT_ERROR: {
    isOpen: true,
    message: 'Please verify all the ingested data & save before submitting it',
    modalType: MODAL_TYPES.ERROR,
  },
  INGESTION_ERROR: {
    isOpen: true,
    message: 'Please verify all the ingested data & either accept or reject it',
    modalType: MODAL_TYPES.ERROR,
  },
  API_ERROR: {
    isOpen: true,
    message: 'There was a problem with the server',
    modalType: MODAL_TYPES.ERROR,
  },
  DUPLICATE_DATA_ERROR: {
    isOpen: true,
    message: 'Combination already exists',
    modalType: MODAL_TYPES.ERROR,
  },
  FEATURE_SUBMIT_VALIDATION_AND_UNSAVED_ERROR: {
    isOpen: true,
    // message: 'There are error(s) in the tab(s), please resolve them to continue',
    message: 'There are unsaved changes and invalid data. Please review',
    modalType: MODAL_TYPES.ERROR,
  },
  FEATURE_SUBMIT_VALIDATION_ERROR: {
    isOpen: true,
    // message: 'There are error(s) in the tab(s), please resolve them to continue',
    message: 'There are invalid data. Please review',
    modalType: MODAL_TYPES.ERROR,
  },
  FEATURE_SUBMIT_UNSAVED_ERROR: {
    isOpen: true,
    // message: 'There are unsaved changes, please save to continue',
    message: 'There are unsaved changes. Please review',
    modalType: MODAL_TYPES.ERROR,
  },
};

export const PROD_STATUS_CONFIRMATION = {
  PENDING_REVIEW_INGESTED: {
    message:
      'Submitting the record will push the record to “Ingested New Record” Queue. Do you want to continue?',
  },
  PENDING_REVIEW: {
    message:
      'Submitting the record  as Pending Review will push the record to “Pending Review (Manual)” Queue. Do you want to continue?',
  },
  IN_PRODUCTION: {
    message:
      'Submitting the record  as “In Production” will push the record to FOS. Do you want to continue?',
  },
  GENERAL: {
    message:
      'Submitting the record will change the production status. Are you sure you want to update?',
  },
  IN_PRODUCTION_TO_OUT_OF_SCOPE: {
    message:
      'Production status has been changed from “In-Production” to “Out of Scope”. Please check again.',
  },
  IN_PRODUCTION_TO_OUT_OF_PRODUCTION: {
    message:
      'Production status has been changed from “In-Production” to “Out of Production”. Please check again.',
  },
  SUBMIT_MODAL: {
    message: 'Record submitted successfully',
  },
};

export const CLEAR_CONFIRMATION = {
  CLEAR_MODAL: {
    message:
      'You have clicked CLEAR button. Your unsaved changes will be deleted. Are you sure you want to continue?',
  },
  CONFIRM_MODAL: {
    message: 'Changes cleared successfully.',
  },
  ERROR_MODAL: {
    message: 'Changes could not be cleared as they have been saved already.',
  },
};
export const DROPDOWN_TYPES = {
  TYPEAHEAD: 'typeahead',
  DROPDOWN_AND_TYPEAHEAD: 'dropdownAndTypeahead',
};

export const VALUES_WITHOUT_NOT = ['DPL Year Launched', 'Drug Key ', 'Drug ID', 'Patent Number'];
export const VALUES_ONLY_IS_OPERATOR = [
  'DPL Current Status',
  'Primary Drug Name (Y/N)',
  'Production Status',
  'NBE',
  'NCE',
  'Development Status',
  'Drug Event Type',
  'Drug Event Disease',
  'Drug Event Company',
  'Drug Event Location',
  'Drug Event Status',
];

export const SEARCH_OPERATOR = {
  EQUALS: 0,
  NOT_EQUALS: 1,
  CONTAINS: 2,
  STARTS_WITH: 3,
  GREATER_THAN: 4,
  LESS_THAN: 5,
};
export const SEARCH_CONDITION = {
  AND: 0,
  OR: 1,
};

export const RECORD_TYPES = {
  TRIALS: 'Trials',
  DRUGS: 'Drugs',
  COMPANY: 'Company',
  PERSON: 'Person',
  ORGANIZATION: 'Organization',
};

export const ENTITY_TYPES = {
  TRIALS: 'trials',
  DRUGS: 'drugs',
  COMPANY: 'company',
  PERSON: 'person',
  ORGANIZATION: 'organization',
  REGISTRY: 'registry',
  SOURCEDOCUMENT: 'sourceDocument',
  COUNTRY: 'country',
  DASHBOARD: 'dashboard',
};

export const REGINTEL_ENTITIES = [
  'registry',
  'sourceDocument',
  'country',
  'requirement',
  'dashboard',
];

export const CLINICAL_ENTITIES = ['person', 'organization', 'clinicalTrials', 'drugs', 'company'];

export const USE_DEVEXTREME_SCROLL = false;

export const CLINICAL_CONFIGURATION = {
  showCheckboxByDefault: true,
  enableSearchPanel: false,
  enableColumnFixing: true,
  enableFilterRow: true,
  enableExport: true,
  enablePaging: true,
  enableSelection: true,
};

export const DISCLOSE_CONFIGURATION = {
  showCheckboxByDefault: true,
  enableSearchPanel: false,
  enableColumnFixing: true,
  enableFilterRow: true,
  enableExport: false,
  enablePaging: true,
  enableSelection: true,
};

export const REGINTEL_ENTITY_BUTTON = [
  'Create New Registry',
  'Create New Document',
  'Create New Country',
  'Create New Requirement',
];

export const ANCILLARY_REQUEST_STATUS = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

export const ANCILLARY_DNA_PARAMS = {
  SPONSOR: 'Sponsor/Collaborator',
  COLLABORATOR: 'Collaborator',
  COMPANY: 'Company',
};

export const ADVANCED_SEARCH_CATEGORY_TITLE_TYPES = {
  1: fieldTypes.text,
  2: fieldTypes.select,
  3: fieldTypes.select_async,
  4: fieldTypes.dateRange,
  5: fieldTypes.multi_select_async,
  6: fieldTypes.date,
  7: fieldTypes.text,
  10: fieldTypes.select,
  11: fieldTypes.select,
  12: fieldTypes.select_async,
  13: fieldTypes.select,
  14: fieldTypes.dateRange,
  15: fieldTypes.text,
  16: fieldTypes.text,
  17: fieldTypes.select,
  18: fieldTypes.select_async,
  19: fieldTypes.text,
  20: fieldTypes.text,
  21: fieldTypes.text,
  22: fieldTypes.text,
  23: fieldTypes.text,
  24: fieldTypes.select,
  25: fieldTypes.text,
  26: fieldTypes.select,
  27: fieldTypes.text,
  28: fieldTypes.select,
  29: fieldTypes.select_async,
  30: fieldTypes.select_async,
  31: fieldTypes.select_tree_async,
  32: fieldTypes.select,
};

export const ADVANCED_SEARCH_CONDITIONS = {
  0: 'AND',
  1: 'OR',
};

export const ADVANCED_SEARCH_ALL_OPERATORS = {
  0: 'IS',
  1: 'IS NOT',
  2: 'CONTAINS',
  3: 'DOES NOT CONTAIN',
  4: 'GREATER THAN',
  5: 'LESS THAN',
  6: 'GREATER THAN OR EQUAL',
  7: 'LESS THAN OR EQUAL',
  9: 'IS',
  10: 'IS NOT',
  11: 'CONTAINS',
  12: 'DOES NOT CONTAIN',
  13: 'IS GREATER THAN',
  14: 'IS LESS THAN',
  15: 'IS GREATER THAN OR EQUAL TO',
  16: 'IS LESS THAN OR EQUAL TO',
  19: 'CONTAINS EXACTLY',
  20: 'CONTAINS ALL OF',
  21: 'CONTAINS ANY OF',
  23: 'IS BETWEEN',
  24: 'IS NOT BETWEEN',
  25: 'IS BEFORE',
  26: 'IS AFTER',
};

export const SEARCH_TYPE = {
  quickSearch: 0,
  advancedSearch: 1,
};

export const showColumnsPostParam = {
  clinicalTrials: ['secondaryDrugs', 'collaborators'],
  drugs: [],
  company: [],
  person: ['licenses'],
  organization: [],
  registry: [],
  sourceDocument: [],
  country: [],
  requirement: [],
  dashboard: [],
};

export const showColumnsOptionalPostParam = {
  person: ['degrees', 'specialities'],
  clinicalTrials: [
    'sponsorTypes',
    'locations',
    'therapeuticAreas',
    'diseases',
    'patientSegments',
    'primaryDrugs',
    'protocolIDs',
    'sponsors',
  ],
  organization: [
    'licenses',
    'street1',
    'street2',
    'city',
    'state',
    'country',
    'postalCode',
    'phoneNumbers',
  ],
  drugs: [
    'deliveryMedium',
    'deliveryRoute',
    'deliveryTechnology',
    'diseases',
    'country',
    'currentDevelopmentStatus',
    'therapeuticClass',
    'drugType',
  ],
  company: [],
  registry: [],
  sourceDocument: [],
  country: [],
  requirement: [],
  dashboard: [],
};

export const advancedSearchListQueueType = { type: 2, name: 'Advanced search' };

export const updateTypeOnProdStatusSave = {
  trials: [10],
  drugs: 12,
  company: 5,
  person: 4,
  organization: 4,
};

export const updateTypeOnStamping = {
  trials: [12],
  drugs: 26,
  company: 6,
  person: 5,
  organization: 5,
  registry: [10],
  requirement: [11],
  country: [4],
  sourceDocument: [4],
};

const defaultPageParam = {
  pageIndex: 1,
  pageSize: 100,
};

export const advancedSearchPostParams = {
  clinicalTrials: {
    ...defaultPageParam,
    resultOptions: {
      showColumns: [],
    },
    searchCriteriaGroups: [
      {
        groupName: 'id',
        searchGroupCondition: 1,
        searchCriteria: [
          {
            columnName: 'id',
            categoryName: 'Trial ID',
            operator: 1,
            searchValue: '0',
            searchValueName: '0',
            condition: 1,
            dataType: 'number',
            groupName: 'id',
          },
        ],
      },
    ],
    sortOrder: [
      {
        columnName: 'id',
        isDescendingOrder: false,
      },
    ],
  },
  company: {
    ...defaultPageParam,
    resultOptions: {
      showColumns: [],
    },
    searchCriteriaGroups: [
      {
        groupName: 'id',
        searchGroupCondition: 1,
        searchCriteria: [
          {
            columnName: 'id',
            categoryName: 'Company ID',
            operator: 1,
            searchValue: '0',
            searchValueName: '0',
            condition: 1,
            dataType: 'number',
            groupName: 'id',
          },
        ],
      },
    ],
    sortOrder: [
      {
        columnName: 'id',
        isDescendingOrder: false,
      },
    ],
  },

  person: {
    ...defaultPageParam,
    resultOptions: {
      showColumns: [],
    },
    searchCriteriaGroups: [
      {
        groupName: 'id',
        searchGroupCondition: 1,
        searchCriteria: [
          {
            columnName: 'id',
            categoryName: 'Person ID',
            operator: 1,
            searchValue: '0',
            searchValueName: '0',
            condition: 1,
            dataType: 'number',
            groupName: 'id',
          },
        ],
      },
    ],
    sortOrder: [
      {
        columnName: 'id',
        isDescendingOrder: false,
      },
    ],
  },
  organization: {
    ...defaultPageParam,
    resultOptions: {
      showColumns: [],
    },
    searchCriteriaGroups: [
      {
        groupName: 'id',
        searchGroupCondition: 1,
        searchCriteria: [
          {
            columnName: 'id',
            categoryName: 'Organization ID',
            operator: 1,
            searchValue: '0',
            searchValueName: '0',
            condition: 1,
            dataType: 'number',
            groupName: 'id',
          },
        ],
      },
    ],
    sortOrder: [
      {
        columnName: 'id',
        isDescendingOrder: false,
      },
    ],
  },
  drugs: {
    ...defaultPageParam,
    resultOptions: {
      showColumns: [],
    },
    searchCriteriaGroups: [
      {
        groupName: 'id',
        searchGroupCondition: 1,
        searchCriteria: [
          {
            columnName: 'id',
            categoryName: 'Drug ID',
            operator: 1,
            searchValue: '0',
            searchValueName: '0',
            condition: 1,
            dataType: 'number',
            groupName: 'id',
          },
        ],
      },
    ],
    // resultOptions: {
    //   showColumns: ['string'],
    // },
  },
};

export const listPagePostParam = {
  trials: {
    ...defaultPageParam,
    resultOptions: {
      showColumns: ['protocolIDs', 'sponsors', 'primaryDrugs', 'diseases'],
    },
  },
  drugs: { ...defaultPageParam },
  company: { ...defaultPageParam },
  sourceDocument: { ...defaultPageParam },
  registry: { ...defaultPageParam },
  country: { ...defaultPageParam },
  requirement: { ...defaultPageParam },
  person: {
    ...defaultPageParam,
    resultOptions: {
      showColumns: ['degrees', 'specialities'],
    },
  },
  organization: {
    ...defaultPageParam,
    resultOptions: {
      showColumns: [
        'licenses',
        'nextFullReviewDate',
        'street1',
        'street2',
        'city',
        'state',
        'country',
      ],
    },
  },
};

export const sortableColumnNames = {
  1: {
    //TRAILS  SORTING COLUMNS
    trialTitle: 'trialtitle.sort',
    id: 'id',
    trialStatus: 'trialstatusid.sort',
    trialPhase: 'trialphase.sort',
    modifiedDate: 'modifieddate',
    lastFullReviewDate: 'lastfullreviewdate',
    nextFullReviewDate: 'nextfullreviewdate',
    createdDate: 'createddate',
    reportedSites: 'reportedsites',
    identifiedSitesNumber: 'identifiedsitesnumber',
    targetAccrual: 'targetaccrual',
    actualAccrual: 'actualaccrual',
    productionStatusId: 'production_status.sort',
    protocolIds: 'sort_protocolid.sort',
    patientSegments: 'sort_patientsegmentid.sort',
    diseaseTypes: 'sort_diseasetypeid.sort',
    sponsors: 'sort_sponsorid.sort',
    primaryDrugs: 'sort_primarydrugid.sort',
    therapeuticAreas: 'sort_therapeuticareaid.sort',
  },
  2: {
    //DRUGS SORTING COLUMNS
    id: 'id',
    drugKey: 'drugkey',
    productionStatus: 'production_status.sort',
    drugSummary: 'summary.sort',
    nce: 'nce',
    nbe: 'nbe',
    modifiedDate: 'modifieddate',
    lastModifiedDateUser: 'modifiedby.sort',
    globalStatus: 'globalstatus.sort',
    latestChangeDate: 'latestchangedate',
    originOfMaterial: 'origin.sort',
    latestChangeText: 'latestchangetext.sort',
    developmentStatus: 'drugdevstatus.sort',
    drugName: 'sort_drugname.sort',
    therapeuticClass: 'sort_therapeuticclass.sort',
    therapeuticClassStatus: 'sort_therapeuticclassstatus.sort',
    mechanismOfAction: 'sort_mechanismofaction.sort',
    biologicaltarget: 'sort_target.sort',
    deliveryRoute: 'sort_deliveryroute.sort',
    deliveryMedium: 'sort_deliverymedium.sort',
    deliveryTechnology: 'sort_deliverytechnology.sort',
    disease: 'sort_dpldisease.sort',
    company: 'sort_dplcompany.sort',
    dplCountry: 'sort_dplcountry.sort',
    currentDevelopmentStatus: 'sort_dplcurrentdevstatus.sort',
    highestDevelopmentStatus: 'sort_dplhighdevstatus.sort',
    origin: 'sort_dplcompanytype.sort',
    launchYear: 'sort_launchyear.sort',
    immunoConjugatePayload: 'sort_immunoconjugate_pl.sort',
  },
  3: {
    //COMPANY  SORTING COLUMNS
    companyName: 'name.sort',
    id: 'id',
    ownership: 'ownership.sort',
    city: 'city.sort',
    country: 'country.sort',
    state: 'state.sort',
    yearEstablished: 'established_year',
    stockExchange: 'stock_exchange.sort',
    lastModifiedDate: 'modifieddate',
    productionStatus: 'production_status.sort',
    drugCount: 'drug_count',
  },
  4: {
    //PERSON  SORTING COLUMNS
    id: 'id',
    firstName: 'first_name.sort',
    lastName: 'last_name.sort',
    middleName: 'middle_name.sort',
    personType: 'person_type.sort',
    regulatoryActionDate: 'regulatory_action_date',
    suffix: 'name_suffix.sort',
    city: 'primary_organization.city.sort',
    country: 'primary_organization.country.sort',
    state: 'primary_organization.state.sort',
    productionStatus: 'production_status.sort',
    lastModifiedDate: 'modifieddate',
    lastReviewUser: 'last_full_review_user.sort',
    siteName: 'primary_organization.organization_name.sort',
    numberofTrialAssociations: 'trials_associations',
    nextReviewDate: 'next_full_review_date',
    degree: 'sort_person_degree.sort',
    specialties: 'sort_person_specialities.sort',
  },
  5: {
    //ORGANIZATION  SORTING COLUMNS
    organizationName: 'name.sort',
    organizationType: 'organization_type.sort',
    id: 'id',
    nextFullReviewDate: 'next_full_review_date',
    modifiedDate: 'modifieddate',
    street1: 'primary_address.street1.sort',
    city: 'primary_address.city.sort',
    state: 'primary_address.state.sort',
    country: 'primary_address.country.sort',
    postalCode: 'primary_address.postal_code.sort',
    parentOrganization: 'parent_org_name.sort',
    parentOrganizationId: 'parent_org_id',
    productionStatusId: 'production_status.sort',
  },
  9: {
    //ORGANIZATION  SORTING COLUMNS
    'PRODUCTION STATUS': 'name.sort',
  },
  7: {
    //ORGANIZATION  SORTING COLUMNS
    'PRODUCTION STATUS': 'name.sort',
  },
  COUNTRY: 'country',
  LRD: 'last_full_review_date',
  NRD: 'next_full_review_date',
  // 'RECORD CREATE DATE': 'created_date',

  //'NEXT FULL REVIEW DATE': 'next_full_review_date', //need to change name to PERSON NEXT FULL REVIEW DATE
};

export const QUEUE_TYPES = {
  ADVANCED_SEARCH: 'ADVANCED SEARCH',
  QUEUE: 'QUEUE',
  TASK: 'TASK',
};

export const childFormChangeObject = { isChanged: () => {}, reset: () => {} };

export const REGISTRY_TYPES = {
  recordUpdateAndSummary: 1,
  generalInformation: 2,
  searchAndStatistics: 3,
  whoEntersData: 4,
  characteristics: 5,
  protocolRegistration: 6,
  resultsDisclosure: 7,
  contactInfo: 8,
};

export const COUNTRY_TYPES = {
  recordUpdateAndSummary: 1,
  generalInformation: 2,
};

export const REQUIREMENT_TYPES = {
  recordUpdateAndSummary: 1,
  clinicalTrialRegulation: 2,
  disclosureRegulationModel: 3,
  RequirementClinicalTrialCharacteristics: 5,
  clinicalTrialCharacteristicsModel: 5,
  contactInfoModel: 9,
  discloseRequirement: 4,
  enforcement: 8,
  protocolRegistrationRequirement: 6,
  resultDisclosureModel: 7,
  RequirementClinicalTrialCharacteristicsB: 8,
  RequirementClinicalTrialCharacteristicsC: 11,
  ProtocolRegistrationRegistry: 6,
  ethicsCommittee: 12,
  plsProtocol: 13,
  plsResults: 14,
};

export const SOURCEDOCUMENT_TYPES = {
  dataEntry: 1,
  sourceDocumentModel: 2,
  uploadFiles: 2,
  downloadFiles: 2,
};

export const sourceDocumentStaticHeader = [
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: 'id',
    formatterType: 0,
    headerName: 'SOURCE DOCUMENT ID',
    id: 225,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 1,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: 'documentTitle',
    formatterType: 0,
    headerName: 'SOURCE DOCUMENT TITLE',
    id: 226,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 3,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 1,
    field: 'productionStatus',
    formatterType: 4,
    headerName: 'PS',
    id: 138,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 7,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: '',
    formatterType: 7,
    headerName: '',
    id: 260,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 33,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: '',
    formatterType: 11,
    headerName: '',
    id: 261,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 34,
    userId: null,
  },
];

export const requirementStaticHeader = [
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: 'id',
    formatterType: 0,
    headerName: 'REQUIREMENT ID',
    id: 225,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 1,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: 'requirementName',
    formatterType: 0,
    headerName: 'REQUIREMENT NAME',
    id: 226,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 3,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: '',
    formatterType: 7,
    headerName: '',
    id: 260,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 33,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: '',
    formatterType: 11,
    headerName: '',
    id: 261,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 34,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: '',
    formatterType: 9,
    headerName: '',
    id: 271,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 35,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 1,
    field: 'productionStatus',
    formatterType: 4,
    headerName: 'PS',
    id: 239,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 7,
    userId: null,
  },
];

export const registryStaticHeader = [
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 6,
    field: 'id',
    formatterType: 0,
    headerName: 'REGISTRY ID',
    id: 225,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 1,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 6,
    field: 'registryName',
    formatterType: 0,
    headerName: 'REGISTRY NAMES',
    id: 226,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 3,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 1,
    field: 'productionStatus',
    formatterType: 4,
    headerName: 'PS',
    id: 239,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 7,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 6,
    field: '',
    formatterType: 7,
    headerName: '',
    id: 260,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 33,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 6,
    field: '',
    formatterType: 11,
    headerName: '',
    id: 261,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 34,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: '',
    formatterType: 9,
    headerName: '',
    id: 271,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 35,
    userId: null,
  },
];

export const countryStaticHeader = [
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 6,
    field: 'id',
    formatterType: 0,
    headerName: 'COUNTRY ID',
    id: 225,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 1,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 6,
    field: 'countryName',
    formatterType: 0,
    headerName: 'COUNTRY NAME',
    id: 226,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 3,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 1,
    field: 'productionStatus',
    formatterType: 4,
    headerName: 'PS',
    id: 239,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 7,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 6,
    field: '',
    formatterType: 7,
    headerName: '',
    id: 260,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 33,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 6,
    field: '',
    formatterType: 11,
    headerName: '',
    id: 261,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 34,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: '',
    formatterType: 9,
    headerName: '',
    id: 271,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 35,
    userId: null,
  },
];
export const dashboardStaticHeader = [
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 10,
    field: 'country',
    formatterType: 0,
    headerName: 'COUNTRY',
    id: 225,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 1,
    userId: null,
    maxWidth: 150,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 10,
    field: 'editionStatus',
    formatterType: 0,
    headerName: 'EDITION STATUS',
    id: 232,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 3,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 10,
    field: 'recordId',
    formatterType: 0,
    headerName: 'RECORD ID',
    id: 226,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 3,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 10,
    field: 'originalPublishedId',
    formatterType: 0,
    headerName: 'ORIGINAL PUBLISHED ID',
    id: 227,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 3,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 10,
    field: 'recordEntity',
    formatterType: 0,
    headerName: 'RECORD ENTITY',
    id: 228,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 3,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 10,
    field: 'recordName',
    formatterType: 0,
    headerName: 'RECORD NAME',
    id: 229,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 3,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 10,
    field: 'editorName',
    formatterType: 0,
    headerName: 'EDITOR NAME',
    id: 230,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 3,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 1,
    field: 'lastEditedDate',
    formatterType: 3,
    headerName: 'LAST EDITED DATE',
    id: 240,
    isVisible: true,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 9,
    userId: null,
  },

  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: '',
    formatterType: 7,
    headerName: '',
    id: 260,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 33,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: '',
    formatterType: 10,
    headerName: '',
    id: 289,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 34,
    userId: null,
  },
  {
    createdBy: null,
    createdDate: '0001-01-01T00:00:00',
    entityTypeId: 4,
    field: '',
    formatterType: 9,
    headerName: '',
    id: 298,
    isVisible: false,
    keyValue: null,
    modifiedBy: null,
    modifiedDate: '0001-01-01T00:00:00',
    order: 34,
    userId: null,
  },
];

export const TEXT_LIMIT = {
  150: 150,
  300: 300,
  400: 400,
  500: 500,
  3000: 3000,
  7000: 7000,
  1000: 1000,
  5000: 5000,
  20000: 20000,
};

export const statusColorRules = [
  { id: 0, display: 'Draft', iconColor: 'red' },
  { id: 1, display: 'Validated', iconColor: 'orange' },
  { id: 2, display: 'Published', iconColor: 'green' },
  { id: 3, display: 'Out of Scope', iconColor: 'black' },
];

export const drugTypeColorRules = [
  { id: 1, display: 'Pharmaprojects Master Drug', iconColor: 'purple' },
  { id: 2, display: 'Pharmaprojects Drug', iconColor: 'purple' },
  { id: 3, display: 'Pharmaprojects Child Drug', iconColor: 'pink' },
  { id: 4, display: 'Trialtrove Only Drug', iconColor: '#d49a90' },
];

export const disclosetimeStamps = [
  {
    dateLabel: 'RECORD CREATE DATE',
    dateAccessor: 'createdDate',
    userLabel: 'USER ID',
    userAccessor: 'createdBy',
  },
  {
    dateLabel: 'VALIDATION DATE',
    dateAccessor: 'validatedDate',
    userLabel: 'USER ID',
    userAccessor: 'validatedUser',
  },
  {
    dateLabel: 'PUBLISHED DATE',
    dateAccessor: 'publishedDate',
    userLabel: 'USER ID',
    userAccessor: 'publishedUser',
  },
  {
    dateLabel: 'LAST FULL REVIEW  DATE',
    dateAccessor: 'lastReviewDate',
    userLabel: 'USER ID',
    userAccessor: 'lastReviewBy',
  },
];
