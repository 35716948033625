/* eslint-disable max-depth */
/* eslint-disable guard-for-in */
import React from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import _, { omit, isEmpty, isUndefined, trim, has, some, isEqual, xorWith } from 'lodash';
import { store } from '../../store/index';
import { InputIcon } from '../../framework/inputs';
import {
  POST,
  GET,
  getTypeaheadSearchUrls,
  getTypeaheadSearchUrlsForQuickAndAdvancedSearch,
} from '../../api';
import drugIngestionFields from '../drugs/ingestionFields';
import trialIngestionFields from '../clinicalTrials/ingestionFields';
import companyIngestionFields from '../company/ingestionFields';
import personIngestionFields from '../person/ingestionFields';
import organizationIngestionFields from '../organization/ingestionFields';
import { getCellRenderer } from './dynamicCellRenderConfig';
import {
  ALERT_MESSAGES,
  PROD_STATUS_CONFIRMATION,
  QUEUE_TYPES,
  REGINTEL_ENTITIES,
  REGISTRY_TYPES,
  REQUIREMENT_TYPES,
} from '../../utils/generic/constants';
import { setErrorDetails } from '../../store/actions/country';
import { setErrorDetail } from '../../store/actions/sourceDocuments';
import { setReqErrorDetails } from '../../store/actions/requirement';
import { VIEW_FIELD_TYPES } from '../../framework/formBuilder/utils';
import validateTabs from './validation';
import { setErrorDetailsRegistry } from '../../store/actions/registry';

export const isValidDate = date => {
  if (date === 'INVALID DATE') {
    return false;
  }
  return true;
};

export const getFormattedTime = date => {
  const currentDate = new Date(date);
  let hours = currentDate.getHours();
  let minutes = currentDate.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours === 0 ? 12 : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes} ${ampm}`;
};

export const getFormattedDate = date => {
  const state = store.getState();
  const month_names = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const currentDate = new Date(date);
  if (date) {
    if (REGINTEL_ENTITIES.includes(state.globalState.selectedFeature)) {
      return `${`0${currentDate.getDate()}`.slice(-2)}-${
        month_names[currentDate.getMonth()]
      }-${`0000${currentDate.getFullYear()}`.slice(-4)}`;
    }
    return `${month_names[currentDate.getMonth()]}-${`0${currentDate.getDate()}`.slice(
      -2,
    )}-${`0000${currentDate.getFullYear()}`.slice(-4)}`;
  } else {
    return 'NA';
  }
};

export const validateUrl = url => {
  let errors = '';
  const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  if (!url) {
    errors = 'Please enter the url';
  } else if (!urlRegex.test(url)) {
    errors = 'Please check the URL again';
  }
  return errors;
};

// TO DO: remove dataType from function paaramters and use typeOf
export const isDataEqual = (current, original, dataType = 'object') => {
  let dataEqual = true;
  switch (dataType) {
    case 'array':
      dataEqual = isEmpty(xorWith(current, original, isEqual));
      break;
    case 'object':
      // to check null and undefined case of object
      if (current && original) {
        // to break loop once we get false
        const currKeys = Object.keys(current);
        const oriKeys = Object.keys(original);
        if (currKeys.length > 0) {
          dataEqual =
            currKeys.length === oriKeys.length &&
            isEmpty(
              currKeys.find(
                key =>
                  !isDataEqual(
                    current[key],
                    original[key],
                    Array.isArray(current[key]) ? 'array' : typeof current[key],
                    key,
                  ),
              ),
            );
        } else {
          dataEqual = `${current}` === `${original}`;
        }
      } else {
        dataEqual = current === original;
      }
      break;
    default:
      dataEqual = current === original;
      break;
  }
  return dataEqual;
};

export const stringSortingComparator = (valueA, valueB) => {
  valueA = valueA.toUpperCase();
  valueB = valueB.toUpperCase();

  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }
  return 0;
};

export const filterDropdownData = (...args) => {
  const [inputValue, data, fieldName] = args;
  return data.filter(val => val[fieldName].toLowerCase().includes(inputValue.toLowerCase()));
};

export const convertDataForSelectDropdown = (data, key, value) => {
  const result = Array.isArray(data) ? [...data] : [];
  return result.map(d => {
    return {
      ...omit(d, [`${key}`, `${value}`]),
      label: d[value],
      value: d[key],
    };
  });
};

export const convertDataForReqLinkSelectDropdown = (data, key, value) => {
  const result = Array.isArray(data) ? [...data] : [];
  return result.map(d => {
    return {
      ...omit(d, [`${key}`, `${value}`]),
      label: `${d[key]} - ${d[value]}`,
      value: d[key],
      id: d[key],
    };
  });
};

export const convertDataForSelectDropdownRegistry = (data, key, value) => {
  const result = Array.isArray(data) ? [...data] : [];
  return result.map(d => {
    return {
      ...omit(d, [`${key}`, `${value}`]),
      id: d[key],
      label: d[value],
      value: d[value],
    };
  });
};

export const convertDataForApi = ({ data, key, value, apiKey, apiValue }) => {
  const result = Array.isArray(data) ? [...data] : [];
  return result.map(d => {
    return {
      ...omit(d, [`${key}`, `${value}`]),
      [apiKey]: d[key],
      [apiValue]: d[value],
    };
  });
};

export const convertDataForRadioOptions = (data, key, value) => {
  const result = Array.isArray(data) ? [...data] : [];
  return result.map(d => {
    return {
      ...omit(d, [`${key}`, `${value}`]),
      id: d[key],
      display: d[value],
    };
  });
};

export const convertDateInstance = date => {
  return date !== null && date !== '0001-01-01T00:00:00' ? new Date(date) : null;
};

export const convertArrayToString = list => {
  let result = '';
  if (list && list.length > 0) {
    list.map(data => (result = result ? `${result}; ${data}` : data));
  }
  return result;
};

export const convertObjectForSelectDropdown = (data, key, value) => {
  const result = checkIfObjectIsEmpty(data) ? null : { ...data };
  if (result) {
    return {
      ...omit(result, [`${key}`, `${value}`]),
      label: result[value],
      value: result[key],
    };
  }
  return result;
};

export const convertObjectForApi = ({ data, key, value, apiKey, apiValue }) => {
  const result = checkIfObjectIsEmpty(data) ? null : { ...data };
  if (result) {
    return {
      ...omit(result, [`${key}`, `${value}`]),
      [apiKey]: result[key],
      [apiValue]: result[value],
    };
  }
  return result;
};

export const checkIfStringIsEmpty = data =>
  data === null || data === undefined || data.toString().trim() === '';

export const checkIfObjectIsEmpty = data => isEmpty(data);

export const checkIfArrayIsEmpty = data => data && data.length === 0;

export const getGridRowIndex = ({ rowData, rowIndex }, gridData) => {
  let rowIdx = rowIndex;
  if (rowData && rowData.id) {
    const filteredRowIndex = [...gridData].findIndex(data => data.id === rowData.id);
    rowIdx = filteredRowIndex > -1 ? filteredRowIndex : rowIndex;
  }
  return rowIdx;
};

export const getApiErrorMessage = messages => {
  let errorMessage = 'There was a problem with the server';
  if (messages && messages.length > 0) {
    errorMessage = messages[0].errorMessage;
  }
  return errorMessage;
};

export const getCompanyDelValidateErrorMessage = message => {
  let drugIds = message.filter(obj => obj.entityName === 'Drugs')[0]?.iDs || [];
  let sponsorIds = message.filter(obj => obj.entityName === 'Sponsors')[0]?.iDs || [];
  let childIds = message.filter(obj => obj.entityName === 'ParentCompany')[0]?.iDs || [];
  let messages = [];
  if (drugIds.length) {
    messages.push(
      <p>
        <b>Company record cannot be deleted as it is associated with Drug ID </b>
        {`${drugIds.sort((a, b) => a - b).join(', ')}`}
      </p>,
    );
  }
  if (sponsorIds.length) {
    messages.push(
      <p>
        <b>Company record cannot be deleted as it is associated with Sponsor ID </b>
        {`${sponsorIds.sort((a, b) => a - b).join(', ')}`}
      </p>,
    );
  }
  if (childIds.length) {
    messages.push(
      <p>
        <b>Company record cannot be deleted as it has child record ID </b>
        {`${childIds.sort((a, b) => a - b).join(', ')}`}
      </p>,
    );
  }
  return messages;
};

export const regexToAllowOnlyPositiveNumbers = new RegExp(/^([1-9][0-9]*|0)$/);

export const regexToAllowPositiveNumbersAndDecimals = new RegExp(/^(0|[1-9]\d*)?(\.\d+)?$/);

export const getRowId = data => {
  const ids = data ? data.map(d => d.id) : [];
  let id = -1;
  if (ids.length > 0) {
    const minId = Math.min(...ids);
    if (minId <= 0) {
      id = minId - 1;
    }
  }
  return id;
};

export const convertValueToThousands = data => {
  return data ? data.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '';
};

export const isEmptyValue = value => {
  return value === '' || value === null || isUndefined(value) || trim(value) === '';
};

// code to form ingested data for all entities
export const formIngestedData = data => {
  let result = {};
  for (const parentKey in data) {
    const parentData = data[parentKey];
    let newData = {
      [parentKey]: {
        data: {},
        fieldActions: {},
      },
    };
    if (parentData && !Array.isArray(parentData)) {
      for (const childKey in parentData) {
        const childData = parentData[childKey];
        if (!isEmptyValue(childData) && !Array.isArray(childData)) {
          newData = {
            ...newData,
            [parentKey]: {
              ...newData[parentKey],
              data: {
                ...newData[parentKey].data,
                [childKey]: childData,
              },
              fieldActions: {
                ...newData[parentKey].fieldActions,
                [childKey]: 'default',
              },
            },
          };
        }
      }
    }
    result = {
      ...result,
      ...newData,
    };
  }
  return result;
};

// code to merge current & ingested data
export const formatIngestedData = (currentValue, ingestedValue) => {
  let data = currentValue;
  if (
    Array.isArray(currentValue) &&
    Array.isArray(ingestedValue) &&
    currentValue.length > 0 &&
    ingestedValue.length > 0
  ) {
    data = [
      ...ingestedValue.map(c => {
        return {
          ...c,
          ingestionAction: 'default',
          isDisabled: true,
        };
      }),
      ...currentValue.map(c => {
        return {
          ...c,
          ingestionAction: 'none',
          isDisabled: true,
        };
      }),
    ];
  } else if (Array.isArray(ingestedValue) && ingestedValue.length > 0) {
    data = ingestedValue.map(c => {
      return {
        ...c,
        ingestionAction: 'default',
        isDisabled: true,
      };
    });
  }
  return data;
};

// code to update current data for all the entites based on ingested data
export const updateCurrentDataBasedOnIngestion = (data, ingestedData) => {
  let result = {};
  for (const parentKey in data) {
    const parentData = data[parentKey];
    let newData = {
      [parentKey]: parentData,
    };
    if (Array.isArray(parentData)) {
      newData = {
        ...newData,
        [parentKey]: formatIngestedData(parentData, ingestedData[parentKey]),
      };
    } else {
      for (const childKey in parentData) {
        const childData = parentData[childKey];
        let ingestedChildData = ingestedData[parentKey];
        ingestedChildData = ingestedChildData ? ingestedChildData[childKey] : null;
        newData = {
          ...newData,
          [parentKey]: {
            ...newData[parentKey],
            [childKey]: formatIngestedData(childData, ingestedChildData),
          },
        };
      }
    }
    result = {
      ...result,
      ...newData,
    };
  }
  return result;
};

const resetArrayIngestionAction = (arr = []) =>
  arr.map(x => {
    if (x.ingestionAction) {
      return x.ingestionAction === 'none'
        ? { ...x, isDisabled: true }
        : { ...x, ingestionAction: 'default', isDisabled: true };
    }
    return x;
  });

export const resetIngestionAction = ({ data, fieldActions }, current, original) => {
  let newFieldActions = {};
  Object.keys(fieldActions).forEach(key => {
    newFieldActions = {
      ...newFieldActions,
      [key]: 'default',
    };
  });

  let updatedCurrent = {};

  if (Array.isArray(current)) {
    updatedCurrent = resetArrayIngestionAction(current);
  } else {
    updatedCurrent = { ...current };
    for (const childKey in current) {
      const childData = current[childKey];
      if (Array.isArray(childData) && checkForIngestionAction(childData, true)) {
        updatedCurrent = {
          ...updatedCurrent,
          [childKey]: resetArrayIngestionAction(childData),
        };
      } else {
        updatedCurrent = {
          ...updatedCurrent,
          [childKey]: original[childKey],
        };
      }
    }
  }

  return {
    ingestedData: { data, fieldActions: newFieldActions },
    updatedCurrentData: updatedCurrent,
  };
};

export const resetChildIngestionAction = ({
  ingestedData: { data, fieldActions },
  current,
  original,
  childKey,
}) => {
  let newFieldActions = {
    ...fieldActions,
  };
  if (childKey in fieldActions) {
    newFieldActions = {
      ...newFieldActions,
      [childKey]: 'default',
    };
  }

  let updatedCurrent = { ...current };

  if (Array.isArray(current[childKey]) && checkForIngestionAction(current[childKey], true)) {
    updatedCurrent = {
      ...updatedCurrent,
      [childKey]: resetArrayIngestionAction(current[childKey]),
    };
  } else {
    updatedCurrent = {
      ...updatedCurrent,
      [childKey]: original[childKey],
    };
  }

  return {
    ingestedData: { data, fieldActions: newFieldActions },
    updatedCurrentData: updatedCurrent,
  };
};

export const regexToCheckForExponentials = new RegExp(/^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)$/);

export const is32BitSignedInteger = value => {
  return value > 2147483647;
};

export const formatDate = string => {
  var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return new Date(string).toLocaleDateString([], options);
};

// code to form a html string for ingested data shown using InputTextAreaAdvanced component
export const formatDataForTextArea = (currentData, ingestedData) => {
  return `<div class="ingested-data-container">${currentData}<span class="ingested-text">${ingestedData}</span></div>`;
};

// code to form data based on field type once user clicks accept/reject icon
export const updateIngestedDataBasedOnFieldType = ({
  type,
  ingestionAction,
  original,
  current,
  ingested,
  parentKey = '',
  childKey = 'id',
}) => {
  let value = '';
  const isAccepted = ingestionAction === 'accept';
  switch (type) {
    case 'string':
      value = isAccepted ? `${current}\n${ingested}` : original;
      break;
    case 'object': {
      const data = isAccepted ? { ...ingested } : { ...original };
      value = isEmpty(data) ? null : convertObjectForSelectDropdown(data, 'id', 'value');
      break;
    }
    case 'array': {
      const data = current.map(d => {
        const currentIngestionAction = d.ingestionAction || 'none';
        let currentData = d[childKey];
        let ingestedData = ingested[childKey];
        if (parentKey) {
          currentData = d[parentKey] ? d[parentKey][childKey] : d[parentKey];
          ingestedData = ingested.value;
        }
        return {
          ...d,
          ingestionAction: ingestedData === currentData ? ingestionAction : currentIngestionAction,
        };
      });
      value = data.map(d => {
        return {
          ...d,
          isDisabled: checkForIngestionAction(data),
        };
      });
      break;
    }
    case 'date': {
      const data = isAccepted ? ingested : original;
      value = data ? new Date(data) : null;
      break;
    }
    default:
      value = isAccepted ? ingested : original;
      break;
  }
  return value;
};

export const regexToCheckForHtmlString = new RegExp(/<\/?[a-z]*>/i);

// code to return classname, inorder to show green color for ingested data
export const getClassNameForIngestion = ingestionAction => {
  return ingestionAction && ingestionAction !== 'none' ? 'ingested-default-text' : '';
};

export const availableIngestionActions = ['default', 'accept', 'reject'];
export const userIngestionActions = ['accept', 'reject'];

/**
 * code to validate if any field data contains ingestion actions
 * @param {*} data  ingested action state
 * @param {boolean} isAllIngestionActions   for ingestion action's column in tables
 * @param {boolean} isUserIngestionActions  for only checking if action is either accept/reject
 * @returns {boolean} if there is ingestion action
 */
export const checkForIngestionAction = (
  data,
  isAllIngestionActions = false,
  isUserIngestionActions = false,
) => {
  let ingestionActions = availableIngestionActions;
  if (isUserIngestionActions) {
    ingestionActions = userIngestionActions;
  } else {
    ingestionActions = isAllIngestionActions ? availableIngestionActions : ['default'];
  }
  return Array.isArray(data) && some(data, o => has(o, 'ingestionAction'))
    ? data.findIndex(d => ingestionActions?.includes(d?.ingestionAction)) > -1
    : ingestionActions.includes(data);
};

// code to remove rejected data from array
export const removeIngestionActionAndData = ingestedData => {
  return ingestedData
    .map(c => {
      if (c.ingestionAction !== 'reject') {
        return {
          ...omit(c, ['ingestionAction', 'isDisabled']),
        };
      }
      return null;
    })
    .filter(n => n !== null);
};

// code to get ingestion fields data from respective entity files
export const getIngestionFields = type => {
  let values = [];
  switch (type) {
    case 'drugs':
      values = drugIngestionFields;
      break;
    case 'trials':
      values = trialIngestionFields;
      break;
    case 'company':
      values = companyIngestionFields;
      break;
    case 'person':
      values = personIngestionFields;
      break;
    case 'organization':
      values = organizationIngestionFields;
      break;
    default:
      break;
  }
  return values;
};

const isIngestedRecord = data => {
  return checkIfArrayIsEmpty(data.filter(s => s.isIngested));
};

const filterScoringDetails = data => {
  return data
    .filter(
      s =>
        s.scoringDetails &&
        (s.scoringDetails.acceptScore !== 0 || s.scoringDetails.rejectScore !== 0),
    )
    .map(s => s.scoringDetails);
};

// code to check for ingestion action & calculate accept/reject score for ingested data
const getIngestionAndScoringDetails = ({ data, ingestionFields, key }) => {
  const fieldDetails = ingestionFields.find(i => i.fieldName === key);
  let scoringDetails = {
    id: 0,
    fieldId: fieldDetails ? fieldDetails.fieldId : '',
    acceptScore: 0,
    rejectScore: 0,
  };
  if (Array.isArray(data)) {
    const ingestedDataLength = data.filter(
      d => d.ingestionAction === 'accept' || d.ingestionAction === 'reject',
    ).length;
    scoringDetails =
      ingestedDataLength > 0
        ? {
            ...scoringDetails,
            acceptScore: Number(
              (
                data.filter(d => d.ingestionAction === 'accept').length / ingestedDataLength
              ).toFixed(2),
            ),
            rejectScore: Number(
              (
                data.filter(d => d.ingestionAction === 'reject').length / ingestedDataLength
              ).toFixed(2),
            ),
          }
        : scoringDetails;
  } else {
    scoringDetails = {
      ...scoringDetails,
      acceptScore: Number(data === 'accept'),
      rejectScore: Number(data === 'reject'),
    };
  }
  return {
    isIngested: checkForIngestionAction(data),
    scoringDetails,
  };
};

//code to validate if the page data contains any ingestion actions on save
export const validateIngestionAction = ({ ingestedActions, data, type, arrayKey = '' }) => {
  let result = null;
  let singleFieldIngestionActionsAndScoringDetails = [];
  let multiFieldIngestionActionsAndScoringDetails = [];
  const ingestionFields = getIngestionFields(type);

  if (!isEmpty(ingestedActions)) {
    singleFieldIngestionActionsAndScoringDetails = Object.keys(ingestedActions).map(i =>
      getIngestionAndScoringDetails({ data: ingestedActions[i], ingestionFields, key: i }),
    );
  }

  if (Array.isArray(data)) {
    multiFieldIngestionActionsAndScoringDetails.push(
      getIngestionAndScoringDetails({ data, ingestionFields, key: arrayKey }),
    );
    result = removeIngestionActionAndData(data);
  } else if (!isEmpty(data)) {
    for (const parentKey in data) {
      const parentData = data[parentKey];
      let newData = {
        [parentKey]: parentData,
      };
      if (Array.isArray(parentData)) {
        multiFieldIngestionActionsAndScoringDetails.push(
          getIngestionAndScoringDetails({
            data: parentData,
            ingestionFields,
            key: parentKey,
          }),
        );
        newData = {
          ...newData,
          [parentKey]: removeIngestionActionAndData(parentData),
        };
      } else {
        for (const childKey in parentData) {
          const childData = parentData[childKey];
          if (Array.isArray(childData)) {
            multiFieldIngestionActionsAndScoringDetails.push(
              getIngestionAndScoringDetails({
                data: childData,
                ingestionFields,
                key: childKey,
              }),
            );
            newData = {
              ...newData,
              [parentKey]: {
                ...newData[parentKey],
                [childKey]: some(childData, o => has(o, 'ingestionAction'))
                  ? removeIngestionActionAndData(childData)
                  : childData,
              },
            };
          }
        }
      }
      result = {
        ...result,
        ...newData,
      };
    }
  }

  return {
    isValid:
      isIngestedRecord(singleFieldIngestionActionsAndScoringDetails) &&
      isIngestedRecord(multiFieldIngestionActionsAndScoringDetails),
    data: result,
    ingestedContentResults: [
      ...filterScoringDetails(singleFieldIngestionActionsAndScoringDetails),
      ...filterScoringDetails(multiFieldIngestionActionsAndScoringDetails),
    ],
  };
};

// code to validate mandatory array fields for any ingestion actions
export const checkIngestionActionForMandatoryFields = data => {
  let isIngested = false;
  if (
    some(data, o => {
      return has(o, 'ingestionAction');
    })
  ) {
    const ingestedData = data.find(i => i.ingestionAction !== 'reject');
    isIngested = checkIfObjectIsEmpty(ingestedData);
  }
  return isIngested;
};

/**
 *
 * @param {any} values current values
 * @param {any} ingestedActions ingestion actions
 * @param {boolean} userAction true - check for accept/reject, false - check for default
 * @returns {boolean} if there is ingestion action
 */
export const checkUserIngestionAction = (values, ingestedActions, userAction = true) => {
  if (values) {
    return Array.isArray(values)
      ? checkForIngestionAction(values, false, userAction)
      : Boolean(
          Object.keys(values).find(key => {
            const data = Array.isArray(values[key]) ? values[key] : ingestedActions[key];
            return data && checkForIngestionAction(data, false, userAction);
          }),
        );
  }
  return false;
};

//check production status delete
export const disableDeleteProdStatus = productionStatus => {
  const prodHardDeleteRules = [0, 1, 2, 3]; // Pending Review or Out of Scope or Out of Production,
  let prodId = productionStatus;
  let disbleDelete = true;
  if (typeof productionStatus === 'object') {
    const { id } = productionStatus ? productionStatus : { id: 0 };
    prodId = id;
  }
  if (prodHardDeleteRules.includes(prodId)) {
    disbleDelete = false;
  }
  return disbleDelete;
};

export const Link = ({ urlLink }) => {
  if (checkIfStringIsEmpty(urlLink)) {
    return 'NA';
  }
  const validLink = urlLink.startsWith('http') ? urlLink : `//${urlLink}`;
  return (
    <a href={validLink} target="_blank" rel="noopener noreferrer">
      {urlLink}
    </a>
  );
};

export const convertDropdownData = data => {
  let parsed = [];
  data.assignee &&
    data.assignee.map(d => {
      d.childrens.map(u => {
        let parserObj = {};
        parserObj.id = 0;
        parserObj.assignee = {};
        parserObj.assignee.userGroup = { id: d.id };
        parserObj.assignee.user = { id: u.id, name: u.value };
        parsed.push(parserObj);
      });
    });
  return parsed;
};

// dynamicColumnDefs - for all the screens

const getColumnConfig = ({
  config,
  isAdmin,
  handleEdit,
  handleManualQueueExit,
  handleProximityView,
  handleCrossTeamNavigation = () => {},
  entity = '',
  selectedFeature,
}) => {
  let configData = config;
  if (!isAdmin) {
    configData = configData.filter(c => c.isAdmin !== 1);
  }
  let parsedConfig = configData.map(configObj => {
    return getCellRenderer({
      configObj,
      handleEdit,
      handleManualQueueExit,
      handleProximityView,
      handleCrossTeamNavigation,
      entity,
      selectedFeature,
    });
  });
  return parsedConfig;
};

export const dynamicColumnDefs = props => {
  const { config } = props;
  if (config.length) {
    return [
      {
        field: '',
        cellClass: ['custom-checkbox'],
        headerClass: 'disableResize',
        minWidth: 50,
        maxWidth: 52,
        resizable: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        lockPosition: true,
        cellRendererParams: params => {
          const { rowIndex, data } = params;
          return {
            id: rowIndex.toString(),
            checked: data,
            value: '',
            label: '',
          };
        },
      },
      ...getColumnConfig({ ...props }),
    ];
  } else {
    return [];
  }
};

export const getProdMessage = (value, previousProductionStatus) => {
  let prodMsg = ALERT_MESSAGES.PROD_STATUS_SAVE;
  if (value === previousProductionStatus) {
    return (prodMsg = ALERT_MESSAGES.PROD_STATUS_VALIDATE);
  }
  // else if (value === 3) {
  //   prodMsg = ALERT_MESSAGES.PROD_STATUS_PENDING_REVIEW_SAVE;
  // } else if (value === 1) {
  //   prodMsg = ALERT_MESSAGES.PROD_STATUS_IN_PRODUCTION_SAVE;
  // } else if (value === 3 && previousProductionStatus === 5) {
  //   prodMsg = ALERT_MESSAGES.PROD_STATUS_OUT_OF_PRODUCTION_SAVE;
  // } else if (value === 2 && previousProductionStatus === 5) {
  //   prodMsg = ALERT_MESSAGES.PROD_STATUS_OUT_OF_PRODUCTION_SAVE;
  // }
  else {
    return prodMsg;
  }
};

export const getMultipleDeleteErrorMessage = responses => {
  let deletedRecordCount = 0,
    undeletedRecords = [];
  Object.keys(responses).map(key => {
    if (responses[key]?.data.state) deletedRecordCount++;
    else undeletedRecords.push(key);
  });
  let errorMessage = [];
  if (deletedRecordCount) {
    errorMessage.push(<p>Deletion completed for {`${deletedRecordCount}`} duplicate records.</p>);
  }
  if (undeletedRecords.length) {
    errorMessage.push(
      <p>
        The following records still have linkage and were not deleted:{' '}
        <b>#{`${undeletedRecords.join(', ')}`}</b>
      </p>,
    );
  }
  return { errorMessage, deletedRecordCount };
};

export const getLockMessageInfo = lockedBy => {
  return `Record being edited by ${lockedBy}`;
};

const findExistingIds = (oldArray, newArray, entity) => {
  let existingIds = [];
  const arrayForCompare = oldArray.map(val => val[entity].id);
  newArray.map(obj => {
    if (arrayForCompare.includes(obj.id)) existingIds.push(obj.id);
  });
  return existingIds;
};

export const formatSourceDocPayload = (model, data) => {
  let newReq, newReg;
  const registries = data.filter(obj => obj.groupLabel === 'Registry');
  const requirements = data.filter(obj => obj.groupLabel === 'Requirement');

  if (registries.length) {
    if (model?.linkingToRegistry.length) {
      const existingIds = findExistingIds(model.linkingToRegistry, registries, 'registry');
      if (existingIds.length)
        newReg = [
          ...model?.linkingToRegistry,
          ...registries
            .filter(obj => !existingIds.includes(obj.id))
            .map(obj => ({ id: 0, productionId: 0, registry: { id: obj.id, value: obj.label } })),
        ];
      else
        newReg = [
          ...model?.linkingToRegistry,
          ...registries.map(obj => ({
            id: 0,
            productionId: 0,
            registry: { id: obj.id, value: obj.label },
          })),
        ];
    } else
      newReg = registries.map(obj => ({
        id: 0,
        productionId: 0,
        registry: { id: obj.id, value: obj.label },
      }));
  }

  if (requirements.length) {
    if (model?.additionalLinkingToRequirement.length) {
      const existingIds = findExistingIds(
        model.additionalLinkingToRequirement,
        requirements,
        'requirement',
      );
      if (existingIds.length)
        newReq = [
          ...model?.additionalLinkingToRequirement,
          ...requirements
            .filter(obj => !existingIds.includes(obj.id))
            .map(obj => ({
              id: 0,
              productionId: 0,
              requirement: { id: obj.id, value: obj.label },
            })),
        ];
      else
        newReq = [
          ...model?.additionalLinkingToRequirement,
          ...requirements.map(obj => ({
            id: 0,
            productionId: 0,
            requirement: { id: obj.id, value: obj.label },
          })),
        ];
    } else
      newReq = requirements.map(obj => ({
        id: 0,
        productionId: 0,
        requirement: { id: obj.id, value: obj.label },
      }));
  }
  return {
    ...model,
    additionalLinkingToRequirement: newReq || model.additionalLinkingToRequirement,
    linkingToRegistry: newReg || model.linkingToRegistry,
  };
};

export const onSearch = async ({ searchedData, url, filterBy = [], isDrugSearch = false }) => {
  // const isPhaseOrTherapticArea = url.includes('StudyPhase')
  //   ? false
  //   : url.includes('Phase') ||
  //     url.includes('TherapeuticArea');
  const isPhaseOrTherapticArea =
    url.includes('Phase') ||
    url.includes('TherapeuticArea') ||
    url.includes('GetInterventionalTypes') ||
    url.includes('GetStudyTypes') ||
    url.includes('GetStudyPhases') ||
    url.includes('countries/Masters') ||
    url.includes('GetRequirementsLinkedWithCountry')
      ? true
      : false;
  try {
    const response = (await isPhaseOrTherapticArea)
      ? await GET(url)
      : await POST(url, {
          searchKeyword: searchedData,
          pageIndex: 1,
          pageSize: 1000,
          filterBy,
        });
    const {
      data: { state, data },
    } = response;
    if (state && data) {
      return isDrugSearch || isPhaseOrTherapticArea ? data : data.results;
    } else {
      if (isPhaseOrTherapticArea) {
        return response.data;
      }
    }

    return null;
  } catch (e) {
    if (!axios.isCancel(e)) {
      return null;
    }
    // if api request is cancelled, then return empty array to avoid showing error modal
    return [];
  }
};

export const getEntityId = {
  trials: 1,
  clinicalTrials: 1,
  drugs: 2,
  company: 3,
  person: 4,
  organization: 5,
  registry: 10,
  sourceDocument: 7,
  country: 8,
  requirement: 9,
  dashboard: 11,
};

export const searchCountry = async value => {
  const filteredData = await onSearch({
    searchedData: value,
    url: getTypeaheadSearchUrls('sites', 'Country'),
  });
  return filteredData ? convertDataForSelectDropdown(filteredData, 'id', 'countryName') : null;
};

export const searchCountryForCompany = async value => {
  const filteredData = await onSearch({
    searchedData: value,
    url: getTypeaheadSearchUrls('drugs', 'Country'),
  });
  return filteredData ? convertDataForSelectDropdown(filteredData, 'id', 'countryName') : null;
};

export const searchState = async (value, countryId) => {
  const filteredData = await onSearch({
    searchedData: value,
    url: getTypeaheadSearchUrls('sites', 'State'),
    filterBy: [{ name: 'CountryId', value: countryId }],
  });
  return filteredData ? convertDataForSelectDropdown(filteredData, 'id', 'stateName') : null;
};
export const searchCity = async (value, stateId) => {
  const filteredData = await onSearch({
    searchedData: value,
    url: getTypeaheadSearchUrls('sites', 'City'),
    filterBy: [{ name: 'StateId', value: stateId }],
  });
  return filteredData ? convertDataForSelectDropdown(filteredData, 'id', 'cityName') : null;
};

export const convertAssigneeSelected = assignees => {
  let assigneeArray = [];
  assignees.forEach(option => {
    option.childrens.forEach(child => {
      let obj = {
        id: 0,
        assignee: {
          userGroup: {
            id: option.id,
          },
          user: {
            id: child.id,
            name: child.value,
          },
        },
      };
      assigneeArray.push(obj);
    });
  });
  return assigneeArray;
};

export const dateFormatter = date =>
  `${date.getUTCFullYear()}-${parseInt(date.getMonth()) + 1}-${date.getDate()}`;

export const getAncillaryRequestPostData = data => {
  const { dnaParameter, recordType, status, comments, id, recordId, value } = data;
  return {
    id: 0,
    value: value ? value : '',
    dnaParameter,
    recordId,
    recordType,
    ancillaryId: id,
    status,
    comment: comments ? comments : '',
    source: '',
    sourceUrl: '',
  };
};

export const isIngestedInComplete = tabs => {
  let ingestedStatus = false;
  if (tabs && tabs.length) {
    tabs.forEach(element => {
      if (element.hasIngestedData) {
        ingestedStatus = true;
      }
    });
  }
  return ingestedStatus;
};

export const scrollElementByName = (name, type) => {
  let errorElement;
  errorElement = type ? document.getElementById(type) : document.querySelector(`[name=${name}]`);
  if (errorElement) {
    errorElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }
};

export const searchPersonAdvanceSearchTypeAhead = async (
  selctedEntity,
  value,
  endPoint,
  searchSubTypeId,
) => {
  const filteredData = await onSearch({
    searchedData: value,
    url: getTypeaheadSearchUrlsForQuickAndAdvancedSearch(
      searchSubTypeId,
      `${selctedEntity}AdvancedSearch`,
      endPoint,
    ),
  });
  console.log('FilterData', filteredData);
  return filteredData ? convertDataForSelectDropdown(filteredData, 'id', 'displayValue') : null;
};

export const searchSavedSearchUsernames = async (selctedEntity, value, searchSubTypeId) => {
  const filteredData = await onSearch({
    searchedData: value,
    url: getTypeaheadSearchUrlsForQuickAndAdvancedSearch(
      searchSubTypeId,
      selctedEntity,
      `${selctedEntity}SavedSearch`,
    ),
  });
  return filteredData ? convertDataForSelectDropdown(filteredData, 'userId', 'userName') : null;
};

export const checkRowCountTask = ({ queueTypeSelected, queueRecords, taskQueueRecords }) => {
  // if (
  //   (queueTypeSelected.type === 2 && queueRecords.rowCount) ||
  //   (queueTypeSelected.type === 1 &&
  //     queueRecords.queueListView &&
  //     queueRecords.queueListView.rowCount)
  // ) {
  //   return true;
};

//   return false;
// };
export const checkRowCount = ({ queueTypeSelected, queueRecords, taskQueueRecords }) => {
  if (
    QUEUE_TYPES.QUEUE === typeOfQueue(queueTypeSelected) ||
    QUEUE_TYPES.ADVANCED_SEARCH === typeOfQueue(queueTypeSelected)
  ) {
    if (
      (queueTypeSelected.type === 2 && queueRecords.rowCount) ||
      (queueTypeSelected.type === 1 &&
        queueRecords.queueListView &&
        queueRecords.queueListView.rowCount)
    ) {
      return true;
    }
  } else if (QUEUE_TYPES.TASK === typeOfQueue(queueTypeSelected)) {
    if (
      (queueTypeSelected.type === 2 && taskQueueRecords.rowCount) ||
      (queueTypeSelected.type === 1 &&
        taskQueueRecords.tasksListView &&
        taskQueueRecords.tasksListView.rowCount)
    ) {
      return true;
    }
  }

  return false;
};

export const getRowCount = ({ queueTypeSelected, queueRecords, taskQueueRecords }) => {
  let rowCount = 0;
  if (
    QUEUE_TYPES.QUEUE === typeOfQueue(queueTypeSelected) ||
    QUEUE_TYPES.ADVANCED_SEARCH === typeOfQueue(queueTypeSelected)
  ) {
    if (queueTypeSelected.type === 1) {
      rowCount =
        queueRecords.queueListView && queueRecords.queueListView.rowCount
          ? queueRecords.queueListView.rowCount
          : 0;
    } else if (queueTypeSelected.type === 2) {
      rowCount = queueRecords.rowCount ? queueRecords.rowCount : 0;
    }
  } else if (QUEUE_TYPES.TASK === typeOfQueue(queueTypeSelected)) {
    if (queueTypeSelected.type === 1) {
      rowCount =
        taskQueueRecords.tasksListView && taskQueueRecords.tasksListView.rowCount
          ? taskQueueRecords.tasksListView.rowCount
          : 0;
    } else if (queueTypeSelected.type === 2) {
      rowCount = taskQueueRecords.rowCount ? taskQueueRecords.rowCount : 0;
    }
  }
  return rowCount;
};
export const getRowCountTask = ({ queueTypeSelected, taskQueueRecords }) => {
  let rowCount = 0;
  if (queueTypeSelected.type === 1) {
    rowCount =
      taskQueueRecords.tasksListView && taskQueueRecords.tasksListView.rowCount
        ? taskQueueRecords.tasksListView.rowCount
        : 0;
  } else if (queueTypeSelected.type === 2) {
    rowCount = taskQueueRecords.rowCount ? taskQueueRecords.rowCount : 0;
  }
  return rowCount;
};

// export const getRecords = ({ queueTypeSelected, queueRecords, taskQueueRecords }) => {
//   const TaskNames = ['BACKLOG']
//   let results = [];
//   if (queueTypeSelected && !TaskNames.includes(queueTypeSelected.name)) {
//     if (queueTypeSelected.type === 1) {
//       results = (queueRecords.queueListView && queueRecords.queueListView.results) || [];
//     } else if (queueTypeSelected.type === 2) {
//       results = queueRecords.results || [];
//     }
//   }
//   if (queueTypeSelected && TaskNames.includes(queueTypeSelected.name)) {
//     if (queueTypeSelected.type === 1) {
//       results = (taskQueueRecords.tasksListView && taskQueueRecords.tasksListView.results) || [];
//     } else if (queueTypeSelected.type === 2) {
//       results = taskQueueRecords.results || [];
//     }
//   }
//   return results;
// };

export const getRecords = ({ queueTypeSelected, queueRecords, taskQueueRecords }) => {
  let results = [];
  switch (typeOfQueue(queueTypeSelected)) {
    case QUEUE_TYPES.QUEUE:
      results = (queueRecords.queueListView && queueRecords.queueListView.results) || [];
      break;
    case QUEUE_TYPES.TASK:
      results = (taskQueueRecords.tasksListView && taskQueueRecords.tasksListView.results) || [];
      break;
    case QUEUE_TYPES.ADVANCED_SEARCH:
      results = queueRecords.results || [];
      break;
    default:
      results = [];
  }

  return results;
};

export const getFormattedDateForView = data => {
  try {
    const res = convertDateInstance(data);
    if (res) {
      return format(res, 'MMM-dd-yyyy').toString();
    }
    return 'NA';
  } catch (err) {
    return 'NA';
  }
};

// ingested = { feature: { data: {}, fieldActions: {} } },
export const hasUnSavedChanges = ({
  current,
  original,
  tabs,
  currentTab,
  ingested,
  ingestedActionsKey = 'fieldActions',
}) =>
  Boolean(
    tabs.find(tab => {
      if (tab.key in current) {
        if (tab.value !== currentTab) {
          if (tab.isTab) {
            return hasUnSavedChanges({
              current: current[tab.key],
              original: original[tab.key],
              tabs: tab.tabs,
              currentTab: '',
              ingested: ingested[tab.key][ingestedActionsKey],
              ingestedActionsKey: '',
            });
          } else {
            return tab.hasIngestedData
              ? checkUserIngestionAction(
                  typeof current[tab.key] === 'string'
                    ? { [tab.key]: current[tab.key] }
                    : current[tab.key],
                  // eslint-disable-next-line no-nested-ternary
                  ingestedActionsKey
                    ? ingested[tab.key][ingestedActionsKey]
                    : typeof ingested[tab.key] === 'string'
                    ? { [tab.key]: ingested[tab.key] }
                    : ingested[tab.key],
                )
              : !isDataEqual(
                  current[tab.key],
                  original[tab.key],
                  Array.isArray(current[tab.key]) ? 'array' : typeof current[tab.key],
                );
          }
        } else if (tab.isTab) {
          return hasUnSavedChanges({
            current: current[tab.key],
            original: original[tab.key],
            tabs: tab.tabs,
            currentTab: tab.tabs[tab.selectedTabIndex].value,
            ingested: ingested[tab.key][ingestedActionsKey],
            ingestedActionsKey: '',
          });
        }
      }
      return false;
    }),
  );

export const tabHasUnsavedChanges = ({ selected, current, original, ingestedActions }) => {
  return selected.hasIngestedData
    ? checkUserIngestionAction(current, ingestedActions)
    : !isDataEqual(current, original, Array.isArray(current) ? 'array' : typeof current);
};

export const getUnSavedChanges = ({
  current,
  original,
  tabs,
  screen,
  currentTab,
  ingested,
  ingestedActionsKey = 'fieldActions',
}) => {
  if (screen === 'person') {
    current = omit(current, ['personBasicInfo.address', 'parentOrganization']);
    original = omit(original, ['personBasicInfo.address', 'parentOrganization']);
  }

  if (screen === 'drugs') {
    current = _.cloneDeep(current);
    current = omit(current, [
      'activity.automaticTargetFamilies',
      'activity.trialTroveDeliveryMediumsFromChild',
      'activity.trialTroveDeliveryRoutesFromChild',
      'activity.trialTroveDeliveryTechnologiesFromChild',
    ]);

    for (let i = 0; i < current.activity.trialTroveDeliveryRoutes.length; i++) {
      current.activity.trialTroveDeliveryRoutes[i].deliveryRoute = omit(
        current.activity.trialTroveDeliveryRoutes[i].deliveryRoute,
        'isMasterCopy',
      );
    }

    for (let i = 0; i < current.activity.trialTroveDeliveryTechnologies.length; i++) {
      current.activity.trialTroveDeliveryTechnologies[i].deliveryTechnology = omit(
        current.activity.trialTroveDeliveryTechnologies[i].deliveryTechnology,
        'isMasterCopy',
      );
    }

    for (let i = 0; i < current.activity.trialTroveDeliveryMediums.length; i++) {
      current.activity.trialTroveDeliveryMediums[i].deliveryMedium = omit(
        current.activity.trialTroveDeliveryMediums[i].deliveryMedium,
        'isMasterCopy',
      );
    }

    for (let i = 0; i < current.activity.biologicalTargets.length; i++) {
      current.activity.biologicalTargets[i].target = omit(
        current.activity.biologicalTargets[i].target,
        'isMasterCopy',
      );
    }

    for (let i = 0; i < current.activity.deliveryMediums.length; i++) {
      current.activity.deliveryMediums[i].deliveryMedium = omit(
        current.activity.deliveryMediums[i].deliveryMedium,
        'isMasterCopy',
      );
    }

    for (let i = 0; i < current.activity.deliveryRoutes.length; i++) {
      current.activity.deliveryRoutes[i].deliveryRoute = omit(
        current.activity.deliveryRoutes[i].deliveryRoute,
        'isMasterCopy',
      );
    }

    for (let i = 0; i < current.activity.deliveryTechnologies.length; i++) {
      current.activity.deliveryTechnologies[i].deliveryTechnology = omit(
        current.activity.deliveryTechnologies[i].deliveryTechnology,
        'isMasterCopy',
      );
    }

    for (let i = 0; i < current.activity.manualTargetFamilies.length; i++) {
      current.activity.manualTargetFamilies[i].targetFamily = omit(
        current.activity.manualTargetFamilies[i].targetFamily,
        'isMasterCopy',
      );
    }

    for (let i = 0; i < current.activity.mechanismOfActions.length; i++) {
      current.activity.mechanismOfActions[i].mechanismOfAction = omit(
        current.activity.mechanismOfActions[i].mechanismOfAction,
        'isMasterCopy',
      );
    }

    for (let i = 0; i < current.activity.drugDrugTypeClassifications.length; i++) {
      current.activity.drugDrugTypeClassifications[i].drugTypeClassification.displayValue =
        current.activity.drugDrugTypeClassifications[i].drugTypeClassification.description;
      current.activity.drugDrugTypeClassifications[i].drugTypeClassification = omit(
        current.activity.drugDrugTypeClassifications[i].drugTypeClassification,
        ['isMasterCopy', 'isSelected', 'label'],
      );
    }

    for (
      let i = 0;
      i < current.chemStructure?.descriptiveData?.drugDrugTypeClassifications.length;
      i++
    ) {
      current.chemStructure.descriptiveData.drugDrugTypeClassifications[
        i
      ].drugTypeClassification.displayValue =
        current.chemStructure.descriptiveData.drugDrugTypeClassifications[
          i
        ].drugTypeClassification.description;
      current.chemStructure.descriptiveData.drugDrugTypeClassifications[
        i
      ].drugTypeClassification = omit(
        current.chemStructure.descriptiveData.drugDrugTypeClassifications[i].drugTypeClassification,
        ['isMasterCopy', 'isSelected', 'label'],
      );
    }
  }

  if (screen === 'clinicalTrial') {
    current = omit(current, ['tempTrialNotes', 'tempTrialResults']);
  }

  let status = [];
  let childTabStatus = [];
  let isChanged = false;
  tabs.forEach((tab, index) => {
    if (tab.key in current) {
      if (tab.value !== currentTab) {
        let changed = false;
        if (tab.isTab) {
          const { status: childStatus, hasUnSavedData: childChanged } = getUnSavedChanges({
            current: current[tab.key],
            original: original[tab.key],
            tabs: tab.tabs,
            currentTab: '',
            ingested: ingested[tab.key][ingestedActionsKey],
            ingestedActionsKey: '',
          });
          changed = childChanged;
          childTabStatus.push({ index, tabs: childStatus });
        } else {
          if (current.activity) {
            delete current.activity.productionStatusId;
          }
          changed = tab.hasIngestedData
            ? checkUserIngestionAction(
                typeof current[tab.key] === 'string'
                  ? { [tab.key]: current[tab.key] }
                  : current[tab.key],
                // eslint-disable-next-line no-nested-ternary
                ingestedActionsKey
                  ? ingested[tab.key][ingestedActionsKey]
                  : typeof ingested[tab.key] === 'string'
                  ? { [tab.key]: ingested[tab.key] }
                  : ingested[tab.key],
              )
            : !isDataEqual(
                current[tab.key],
                original[tab.key],
                Array.isArray(current[tab.key]) ? 'array' : typeof current[tab.key],
              );
        }
        isChanged = isChanged || changed;
        status.push({ index, hasUnSavedData: changed });
      } else if (tab.isTab) {
        const { status: childStatus, hasUnSavedData: childChanged } = getUnSavedChanges({
          current: current[tab.key],
          original: original[tab.key],
          tabs: tab.tabs,
          currentTab: tab.tabs[tab.selectedTabIndex].value,
          ingested: ingested[tab.key][ingestedActionsKey],
          ingestedActionsKey: '',
        });

        isChanged = isChanged || childChanged;
        status.push({ index, hasUnSavedData: childChanged });
        childTabStatus.push({ index, tabs: childStatus });
      }
    }
  });
  return { status, hasUnSavedData: isChanged, childTabStatus };
};

export const getURLData = entityName => {
  const urlData = window.location.search.substring(1);
  const IdsArr = urlData.split('&');
  const isIngestedId = IdsArr.pop().split('=')[1];
  let selectedIds = [];
  for (var i = 0; i < IdsArr.length; i++) {
    let pair = IdsArr[i].split('=');
    if (pair[0].toLowerCase() == entityName.toLowerCase()) {
      selectedIds.push(parseInt(pair[1]));
    }
  }
  return { isIngestedId, selectedIds };
};

export const validateOnSubmit = ({
  current,
  tabs,
  drugType,
  original = {},
  screen,
  currentTab,
  ingested,
  errors = {},
  ingestedActionsKey = 'fieldActions',
  isMandatory,
  disableErrorDisplay,
}) => {
  console.log(tabs, 'tabstabs');
  console.log(tabs, 'current');

  let status = [];
  let childTabStatus = [];
  let isValid = true;
  let errorDetails = {};
  let currentTabId;
  tabs.forEach((tab, index) => {
    if (tab?.key in current && (validateTabs[screen][tab?.key] || tab?.hasIngestedData)) {
      let valid = true;
      if (currentTab === tab.value) {
        currentTabId = index;
      }
      if (currentTab != tab.value) {
        if (tab.isTab) {
          const { status: childStatus, isValid: childValid } = validateOnSubmit({
            current: current[tab.key],
            tabs: tab.tabs,
            currentTab: '',
            ingested: ingested[tab.key][ingestedActionsKey],
            ingestedActionsKey: '',
            errors: errors[tab.key],
            original,
            screen,
            drugType,
            disableErrorDisplay,
          });
          valid = childValid;
          childTabStatus.push({ index, tabs: childStatus });
        } else {
          if (localStorage.getItem('usergroup') == 'RegIntelUser') {
            if (tab.disabled) {
              errorDetails = {};
            } else {
              errorDetails = validateTabs[screen][tab.key](
                { ...current[tab.key], isMandatory },
                true,
                true,
                tab,
              );
            }

            if (errorDetails) {
              if (screen === 'country') {
                store.dispatch(setErrorDetails({ errorDetails, tabs, tabIndex: tab.key }));
              }
              if (screen === 'sourceDocuments' && !disableErrorDisplay) {
                store.dispatch(setErrorDetail({ errorDetails, tabs, tabIndex: tab.key }));
              }
              if (screen === 'requirement') {
                store.dispatch(setReqErrorDetails({ errorDetails, tabs, tabId: index }));
              }
              if (screen === 'registry') {
                store.dispatch(setErrorDetailsRegistry({ errorDetails, tabs, tabIndex: tab.key }));
              }
            }
            const hasError = Object.keys(errorDetails)?.length > 0;
            status.push({ index, isValid: !hasError, tabId: index, hasError });
          } else {
            valid = tab.hasIngestedData
              ? validateIngestionAction({
                  data: current[tab.key],
                  // eslint-disable-next-line no-nested-ternary
                  ingestedActions: ingestedActionsKey
                    ? ingested[tab.key][ingestedActionsKey]
                    : typeof ingested[tab.key] === 'string'
                    ? { [tab.key]: ingested[tab.key] }
                    : ingested[tab.key],
                  type: screen,
                }).isValid
              : checkIfObjectIsEmpty(
                  validateTabs[screen][tab.key](
                    current[tab.key],
                    original,
                    errors[tab.key],
                    drugType,
                  ),
                );
            status.push({ index, isValid: valid });
          }
        }
        isValid = isValid && valid;
      } else if (tab.isTab) {
        const { status: childStatus, isValid: childValid } = validateOnSubmit({
          current: current[tab.key],
          tabs: tab.tabs,
          currentTab: tab.tabs[tab.selectedTabIndex].value,
          ingested: ingested[tab.key][ingestedActionsKey],
          ingestedActionsKey: '',
          errors: errors[tab.key],
          original,
          screen,
          disableErrorDisplay,
        });

        isValid = isValid && childValid;
        status.push({ index, isValid: childValid });
        childTabStatus.push({ index, tabs: childStatus });
      }
    }
  });
  return { status, isValid, childTabStatus, currentTabId };
};

const getDefaultValueForFieldView = viewType => {
  let value = '';
  switch (viewType) {
    case VIEW_FIELD_TYPES.PLAIN:
      value = 'NA';
      break;
    case VIEW_FIELD_TYPES.TABLE:
      value = [];
      break;
    default:
      break;
  }
  return value;
};

export const getGridSizeForCompare = data => {
  let size = 5;
  switch (data.length) {
    case 2:
      size = 5;
      break;
    case 3:
      size = 3;
      break;
    case 4:
      size = 2;
      break;
    default:
      break;
  }
  return size;
};

export const getFieldViewValue = ({ fieldConfig, recordData, primaryKey }) => {
  const { fieldLabel, fieldKey, fieldView, fieldStyles } = fieldConfig;
  let labelVal = [
    {
      value:
        fieldView === VIEW_FIELD_TYPES.PLAIN || fieldView === VIEW_FIELD_TYPES.LABEL
          ? fieldLabel
          : '',
      size: recordData.length === 2 ? 2 : 3,
      cssClass: fieldStyles.labelClass || '',
    },
  ];
  const updData = [...recordData].map(data => {
    if (data[primaryKey] && fieldView !== VIEW_FIELD_TYPES.LABEL) {
      const dataValue = data[primaryKey][fieldKey] || getDefaultValueForFieldView(fieldView);
      return {
        value: dataValue,
        size: getGridSizeForCompare(recordData),
      };
    } else {
      return {
        value: getDefaultValueForFieldView(fieldView),
        size: getGridSizeForCompare(recordData),
      };
    }
  });
  return [...labelVal, ...updData];
};

export const findIndexAndUpdateData = ({ id, data, key = 'id', dataToUpdate = null }) => {
  const updatedData = data ? [...data] : [];
  const index = updatedData.findIndex(a => a[key] === id);
  if (index > -1) {
    if (dataToUpdate) {
      let dataToUpdateNew = { ...dataToUpdate };
      if (updatedData[index]?.isNew) {
        dataToUpdateNew.isNew = true;
        dataToUpdateNew.indicatorFlag = -1;
      }
      updatedData.splice(index, 1, dataToUpdateNew);
    } else {
      updatedData.splice(index, 1);
    }
  }
  return updatedData;
};

export const getFormattedUrlStatus = urlStatus => {
  const { id = '', value = '' } = { ...urlStatus };
  let label = '';
  switch (value && value.toLowerCase()) {
    case 'active':
      label = <InputIcon label="Active" color="green" />;
      break;
    case 'inactive':
      label = <InputIcon label="InActive" color="red" />;
      break;
    default:
      label = <InputIcon label="Broken" color="black" />;
      break;
  }
  return label
    ? {
        label,
        value: id,
      }
    : null;
};

export const highlightText = (value, search) => {
  const escapeRegExp = string => {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& Inserts the matched string
  };
  if (search && value) {
    const searchRegexp = new RegExp(escapeRegExp(search), 'gi');
    const unMatched = value.split(searchRegexp);
    let matched = value.match(searchRegexp);
    matched = matched ? [...matched, ''] : [''];
    const result = unMatched.map(data => {
      return htmlencode(data.toString());
    });
    return result.reduce((acc, cur, i) => {
      return `${acc}${cur}<span class='search-match'>${matched[i]}</span>`;
    }, '');
  }
  const HistoricalDataValue = htmlencode(value.toString());
  return HistoricalDataValue;
};

export const checkWarningBasedOnProdStatus = ({ psStatus, ingestedId, productionStatusID }) => {
  let confirmationMsg = '';
  if (psStatus === 0) {
    confirmationMsg = PROD_STATUS_CONFIRMATION.GENERAL.message;
    return { showError: false, confirmationMsg };
  }
  // else if (psStatus) {
  //   if (ingestedId && ingestedId > 0 && psStatus == 3) {
  //     confirmationMsg = PROD_STATUS_CONFIRMATION.PENDING_REVIEW_INGESTED.message;
  //   } else if (psStatus == 3) {
  //     confirmationMsg = PROD_STATUS_CONFIRMATION.PENDING_REVIEW.message;
  //   } else if (psStatus == 1) {
  //     confirmationMsg = PROD_STATUS_CONFIRMATION.IN_PRODUCTION.message;
  //   } else if (productionStatusID === 1 && psStatus === 2) {
  //     confirmationMsg = PROD_STATUS_CONFIRMATION.IN_PRODUCTION_TO_OUT_OF_SCOPE.message;
  //   } else if (productionStatusID === 1 && psStatus === 6) {
  //     confirmationMsg = PROD_STATUS_CONFIRMATION.IN_PRODUCTION_TO_OUT_OF_PRODUCTION.message;
  //   } else {
  //     confirmationMsg = PROD_STATUS_CONFIRMATION.GENERAL.message;
  //   }
  //   return { confirmationMsg };
  // }
  else {
    confirmationMsg = PROD_STATUS_CONFIRMATION.GENERAL.message;
    return { confirmationMsg };
  }
};

export const htmlencode = str => {
  return str.replace(/[&<>"']/g, function($0) {
    return '&' + { '&': 'amp', '<': 'lt', '>': 'gt', '"': 'quot', "'": '#39' }[$0] + ';';
  });
};

export const htmldecode = text => {
  let textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
};
let recordStatus =
  localStorage.getItem('usergroup') == 'RegIntelUser'
    ? [
        { id: 0, display: 'Draft' },
        { id: 1, display: 'Validated' },
        { id: 2, display: 'Published' },
        { id: 3, display: 'Out of scope' },
      ]
    : [
        { id: 0, display: 'Out Of Production' },
        { id: 1, display: 'In Production' },
        { id: 2, display: 'Out Of Scope' },
        { id: 3, display: 'Pending Review' },
        { id: 4, display: 'Data Check' },
        { id: 5, display: 'External Source' },
        { id: 6, display: 'Out Of Production' },
      ];

export const toolTipValues = recordStatus;

export const drugTypeToolTipValues = {
  1: {
    display: 'Pharmaprojects Drug',
  },
  2: {
    display: 'Pharmaprojects Drug',
  },
  3: {
    display: 'Pharmaprojects Child Drug',
  },
  4: {
    display: 'Trialtrove Only Drug',
  },
};

export const typeOfQueue = queueTypeSelected => {
  let type = null;
  if (queueTypeSelected) {
    if (queueTypeSelected.type === 2) {
      type = QUEUE_TYPES.ADVANCED_SEARCH;
    } else if (queueTypeSelected.type === 1) {
      if (queueTypeSelected.lpSection === 1) {
        type = QUEUE_TYPES.TASK;
      } else {
        type = QUEUE_TYPES.QUEUE;
      }
    }
  }
  return type;
};

export const createGetCurrentValue = ref => field => ref?.current?.values[field];

export const createTouchedFields = (fields = [], value = true) =>
  fields.reduce((init, field) => {
    return { ...init, [field]: value };
  }, {});

export const setTouchedFields = (ref, isTouched = true, key = 'values') => {
  if (ref.current) {
    setTouched(ref, createTouchedFields(Object.keys(ref.current[key]), isTouched));
  }
};

export const setFieldErrors = (ref, errors = {}) => {
  if (ref.current) {
    ref.current.setErrors({ ...ref.current.errors, ...errors });
  }
};

export const setFieldValues = (ref, values = {}) => {
  if (ref.current) {
    ref.current.setValues({ ...ref.current.values, ...values });
  }
};

export const setTouched = (ref, touched = {}) => {
  if (ref.current) {
    ref.current.setTouched({ ...ref.current.touched, ...touched });
  }
};

export const setValidateFields = (ref, isValidate = true) => {
  if (ref.current) {
    setFieldValues(ref, { isValidate });
  }
};

export const validateCurrentForm = async ref => {
  if (ref.current) {
    await ref.current.validateForm();
    const {
      current,
      current: { values },
    } = ref;
    if (Object.keys(current.errors).length > 0) {
      ref.current.setTouched({
        ...ref.current.touched,
        ...(createTouchedFields(Object.keys(current.errors)) || {}),
      });
      return true;
    }
    return false;
  }
};

export const checkIsSameValue = (value1, value2, key = '') =>
  value1
    ?.map(value => value.value)
    .every(value => value2?.map(value => value[key]?.value).includes(value));

export const onSearchPost = async ({ searchedData, url, filterBy = [], isDrugSearch = false }) => {
  try {
    const response = await POST(url, {
      searchKeyword: searchedData,
      pageIndex: 1,
      pageSize: 100,
      filterBy,
    });
    const {
      data: { state, data },
    } = response;
    if (state && data) {
      return isDrugSearch ? data : data.results;
    }
    return null;
  } catch (e) {
    if (!axios.isCancel(e)) {
      return null;
    }
    // if api request is cancelled, then return empty array to avoid showing error modal
    return [];
  }
};

export const isCheckRadioOptionSelected = (values, fieldName = '') => {
  if (fieldName === 'resultDisclosure' && values?.resultDisclosureFormats?.length > 0) {
    const resultDisclosureFormatsSelected = values?.resultDisclosureFormats?.filter(item => {
      return item.mandatoryDisclosure || item.notCoveredInRegulation || item.voluntaryDisclosure;
    });
    if (values?.resultDisclosureFormats?.length !== resultDisclosureFormatsSelected.length) {
      return true;
    }
  }
  if (fieldName === 'resultsDisclosureDueDate' && values?.resultsDisclosureDueDate?.length > 0) {
    let resultsDisclosureDueDateSelected = values?.resultsDisclosureDueDate?.filter(item => {
      return item.afterDate || item.beforeDate || item.onDate;
    });
    if (values?.resultsDisclosureDueDate?.length !== resultsDisclosureDueDateSelected.length) {
      return true;
    }
  }
  return false;
};

export const checkRadioOption = (currentData, originalData, selectOptions = []) => {
  const options = ['id', 'productionId', 'tabId', ...selectOptions];
  let currentResultFormats = currentData?.filter(({ isSelected }) => isSelected);
  if (originalData?.length !== currentResultFormats?.length) {
    return true;
  }
  let checkChangeoption = originalData?.map(x => {
    let checkId = currentResultFormats?.find(d => d.id === x.id) || {};
    const obj = options.reduce((init, value) => {
      return { ...init, [value]: checkId[value] };
    }, {});
    if (!_.isEqual(x, obj)) {
      return x;
    }
  });
  checkChangeoption = checkChangeoption?.filter(x => x !== undefined);
  if (checkChangeoption?.length > 0) {
    return true;
  }

  return false;
};
export const checkIsOptionSelected = (obj = {}, options) =>
  Object.keys(obj)
    .filter(key => options.includes(key))
    ?.some(key => {
      return obj[key];
    });

const checkIsModalSelected = (arr, options, isMandatory) => {
  if (isMandatory) {
    return (
      arr?.length > 0 &&
      arr?.every(value => {
        const isOptionSelected = checkIsOptionSelected(value, options);
        return isOptionSelected;
      })
    );
  } else if (arr?.length > 0) {
    return arr?.every(value => {
      const isOptionSelected = checkIsOptionSelected(value, options);
      return isOptionSelected;
    });
  }
  return true;
};

export const createCheckIsModalSelected = options => {
  return (arr, isMandatory) => checkIsModalSelected(arr, options, isMandatory);
};

export const getCurrentTab = (arr, tab) => arr?.filter(({ tabId }) => tabId === tab?.tabId);

export const addComma = (index, length) => (index !== 0 && length > 1 ? ', ' : '');

export const getRadioValue = (object, type) => {
  let radioValue = '';
  switch (type) {
    case 'result_format':
    case 'clinical':
      const { mandatoryDisclosure, notCoveredInRegulation, voluntaryDisclosure } = object;
      if (mandatoryDisclosure)
        radioValue = type === 'result_format' ? 'Mandatory format' : 'Mandatory disclosure';
      else if (notCoveredInRegulation)
        radioValue =
          type === 'result_format'
            ? 'Format not covered in the regulation/requirement'
            : 'Not covered in regulation/requirement';
      else if (voluntaryDisclosure)
        radioValue =
          type === 'result_format' ? 'Voluntary/optional format' : 'Voluntary disclosure';
      break;
    case 'protocol_format':
      const { postingNotDiscussedInRegulation, postingRequired, postingVoluntaryOptional } = object;
      if (postingNotDiscussedInRegulation)
        radioValue = 'Posting not covered in regulation/requirement';
      else if (postingRequired) radioValue = 'Posting required';
      else if (postingVoluntaryOptional) radioValue = 'Posting voluntary/optional';
      break;
    case 'milestone':
      const { afterThisDate, beforeThisDate, onThisDate } = object;
      if (afterThisDate) radioValue = 'After this date';
      else if (beforeThisDate) radioValue = 'Before this date';
      else if (onThisDate) radioValue = 'On this date';
      break;
    case 'protocol_additional':
      const { notCoveredRequirement, submissionOptional, submissionRequired } = object;
      if (notCoveredRequirement) radioValue = 'Not covered in the regulation/requirement';
      else if (submissionOptional) radioValue = 'Submission to registry voluntary/optional';
      else if (submissionRequired) radioValue = 'Submission to registry required';
      break;
    case 'protocol_publicly_available':
    case 'protocol_publicly_additional':
      const { fullyPubliclyAvailable, mostPubliclyAvailable, notPubliclyAvailable } = object;
      if (fullyPubliclyAvailable) radioValue = 'Fully publicly available';
      else if (mostPubliclyAvailable) radioValue = 'Mostly publicly available';
      else if (notPubliclyAvailable)
        radioValue = 'Not clearly defined if fully or mostly publicly available';
      break;
    case 'result_milestone':
      const { afterDate, beforeDate, onDate } = object;
      if (afterDate) radioValue = 'After this date';
      else if (beforeDate) radioValue = 'Before this date';
      else if (onDate) radioValue = 'On this date';
      break;
    case 'protocol_reg_isProtocolUpdatePosted':
      const { protocolUpdateRegistered } = object;
      radioValue = protocolUpdateRegistered?.value;
      break;
    case 'protocol_reg_isProtocolRegistered':
      const { protocolRegistered } = object;
      radioValue = protocolRegistered?.value;
      break;
    case 'result_isResultsPosted':
      const { resultsPosted } = object;
      radioValue = resultsPosted?.value;
      break;
    case 'result_isResultUpdatePosted':
      const { resultUpdatePosted } = object;
      radioValue = resultUpdatePosted?.value;
      break;
    default:
      break;
  }
  if (radioValue) return ` - ${radioValue}`;
  else return '';
};

export const checkEmptynReturnNull = data => {
  let dataTocheck = '';
  dataTocheck = data.trim(); //?.replace(/(\r\n|\n|\r)/gm, '')
  if (isEmptyValue(dataTocheck)) {
    return null;
  } else {
    return dataTocheck;
  }
};
export const checkEmptynReturnNullForRequirement = data => {
  let dataTocheck = '';
  dataTocheck = data?.trim();
  if (isEmptyValue(dataTocheck)) {
    return null;
  } else {
    return dataTocheck;
  }
};
export const checkIsModalSaved = (
  original = [],
  current = [],
  key = '',
  options = ['fullyorMostlyPubliclyAvailable', 'notPubliclyAvailable'],
) => {
  const selectedModel = current.filter(data => data[key]?.isSelected);
  const checkIsModelOptionSelected = model =>
    model?.every(data => options.some(value => data[value]));
  return (
    original.length === selectedModel.length &&
    checkIsModelOptionSelected(selectedModel) === checkIsModelOptionSelected(original)
  );
};

export const validateIsCurrentFormError = async ref => {
  if (ref.current) {
    const isError = await ref.current.validate();
    return isError;
  }
};

export const addInceptionDate = (dateFromAPI, initialDate) => {
  let recordInceptionDateInfo = {};
  if (dateFromAPI) recordInceptionDateInfo = { recordInceptionDate: dateFromAPI };
  else if (initialDate) recordInceptionDateInfo = { recordInceptionDate: initialDate };
  return recordInceptionDateInfo;
};
export const checkIsReqModalSaved = (
  original = [],
  current = [],
  tabId,
  options = ['fullyorMostlyPubliclyAvailable', 'notPubliclyAvailable'],
) => {
  const selectedModel = current.filter(data => data.isSelected);
  const originalTab = original.filter(data => data.tabId === tabId);
  const checkIsModelOptionSelected = model =>
    model?.every(data => options.some(value => data[value]));
  return (
    originalTab.length === selectedModel.length &&
    checkIsModelOptionSelected(selectedModel) === checkIsModelOptionSelected(originalTab)
  );
};

export const setMandatoryFields = (formSchema, isMandatory, conditionalFields = []) => {
  return Object.keys(formSchema).reduce((init, field, i) => {
    return {
      ...init,
      [field]: {
        ...formSchema[field],
        props: {
          ...formSchema[field].props,
          ...(!conditionalFields.includes(field) &&
          [false, true].includes(formSchema[field].props?.isMandatory)
            ? { isMandatory }
            : {}),
        },
      },
    };
  }, {});
};

export const searchRequirementLinkedWithCountry = async id => {
  const filteredData = await onSearch({
    searchedData: '',
    url: getTypeaheadSearchUrls('RequirementLinkedWithCountry', id),
  });
  return filteredData ? convertDataForSelectDropdown(filteredData, 'id', 'value') : null;
};

export const getPubliclyAvailable = (fullyorMostlyPubliclyAvailable, notPubliclyAvailable) => {
  if (fullyorMostlyPubliclyAvailable) return 'FULLY OR MOSTLY PUBLICLY AVAILABLE';
  else if (notPubliclyAvailable) return 'NOT PUBLICLY AVAILABLE';
  else return 'NA';
};

export const getMandatoryVoluntary = (
  mandatoryDisclosure,
  voluntaryDisclosure,
  notCoveredInRegulation,
) => {
  if (mandatoryDisclosure) return 'MANDATORY FORMAT';
  else if (voluntaryDisclosure) return 'VOLUNTARY/OPTIONAL FORMAT';
  else if (notCoveredInRegulation) return 'FORMAT NOT COVERED IN THE REGULATION/REQUIREMENT';
  else return 'NA';
};

export const getDueDate = (beforeDate, onDate, afterDate) => {
  if (beforeDate) return 'BEFORE THIS DATE';
  else if (onDate) return 'ON THIS DATE';
  else if (afterDate) return 'AFTER THIS DATE';
  else return 'NA';
};

export const getPosting = (
  postingRequired,
  postingVoluntaryOptional,
  postingNotDiscussedInRegulation,
) => {
  if (postingRequired) return 'POSTING REQUIRED';
  else if (postingVoluntaryOptional) return 'POSTING VOLUNTARY/OPTIONAL';
  else if (postingNotDiscussedInRegulation) return 'POSTING NOT COVERED IN REGULATION/REQUIREMENT';
  else return 'NA';
};

export const getSubmission = (submissionRequired, submissionOptional, notCoveredRequirement) => {
  if (submissionRequired) return 'SUBMISSION TO REGISTRY REQUIRED';
  else if (submissionOptional) return 'SUBMISSION TO REGISTRY VOLUNTARY/OPTIONAL';
  else if (notCoveredRequirement) return 'NOT COVERED IN REGULATION/REQUIREMENT';
  else return 'NA';
};

export const getProtocolPubliclyAvailable = (
  fullyPubliclyAvailable,
  mostPubliclyAvailable,
  notPubliclyAvailable,
) => {
  if (fullyPubliclyAvailable) return 'FULLY PUBLICLY AVAILABLE';
  else if (mostPubliclyAvailable) return 'MOSTLY PUBLICLY AVAILABLE';
  else if (notPubliclyAvailable) return 'NOT CLEAR DEFINED IF FULLY OR MOSTLY PUBLICLY AVAILABLE';
  else return 'NA';
};
