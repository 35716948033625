/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable new-cap */
import axios, { CancelToken, post } from 'axios';
import { call } from 'redux-saga/effects';
import { authProvider } from '../auth/authProvider';
import { ageingType } from '../utils/administrator/constants';

export const drugsUrl = process.env.REACT_APP_API_ENDPOINT_DRUGS;
export const trialsUrl = process.env.REACT_APP_API_ENDPOINT_TRIALS;
export const sitesUrl = process.env.REACT_APP_API_ENDPOINT_SITES;
export const administratorUrl = process.env.REACT_APP_API_ENDPOINT_ADMIN;
export const advancedSearchUrl = '';
export const reportingUrl = process.env.REACT_APP_API_ENDPOINT_REPORTING;
export const jchemDBTable = process.env.REACT_APP_JCHEM_DB_TABLE;
export const jchemEndpoint = process.env.REACT_APP_JCHEM_ENDPOINT;
export const registryUrl = process.env.REACT_APP_API_ENDPOINT_REGISTRY;
export const sourceDocumentUrl = process.env.REACT_APP_API_ENDPOINT_SOURCEDOCUMENT;
export const countryUrl = process.env.REACT_APP_API_ENDPOINT_COUNTRY;
export const requirementUrl = process.env.REACT_APP_API_ENDPOINT_REQUIREMENT;

const apiTimeout = 1500000;
const countries = `${sitesUrl}/api/country`;

// Configure axios defaults:
const axiosDefaults = {
  headers: { 'Content-Type': 'application/json' },
};

// config with cancellation token
export const axiosConfig = axios.create({
  ...axiosDefaults,
  timeout: apiTimeout,
});

let pendingRequests = [];

export const cancelAndRemovePendingRequest = config => {
  pendingRequests = pendingRequests
    .map(request => {
      if (request.requestId === `${config.method}-${config.url}`) {
        if (request.cancel) {
          if (
            (typeof config?.data === 'object' && config?.data?.disableCancel) ||
            (typeof config?.data === 'string' && JSON.parse(config?.data)?.disableCancel)
          )
            return null;

          request.cancel('Request Canceled.');
        }
        return null;
      }
      return request;
    })
    .filter(f => f !== null);
};

// request interceptor with cancellation token
axiosConfig.interceptors.request.use(async config => {
  const token = await authProvider.getAccessToken({
    scopes: [process.env.REACT_APP_AUTHENTICATIONPARAMETERS_SCOPES],
  });

  if (token) {
    config.headers.Authorization = `Bearer ${token.accessToken}`;
    localStorage.setItem('token', `Bearer ${token.accessToken}`);
  }
  cancelAndRemovePendingRequest(config);
  config.cancelToken = new CancelToken(c => {
    pendingRequests.push({
      requestId: `${config.method}-${config.url}`,
      cancel: c,
    });
  });
  return config;
});

axiosConfig.interceptors.response.use(response => {
  cancelAndRemovePendingRequest(response.config);
  return response;
});

// config without cancellation token
const axiosConfigWithoutCancelToken = axios.create({
  ...axiosDefaults,
  timeout: apiTimeout,
});

const axiosConfigWithFormData = axios.create({
  headers: {
    'content-type': 'multipart/form-data',
  },
  timeout: apiTimeout,
});

axiosConfigWithFormData.interceptors.request.use(async config => {
  const token = await authProvider.getAccessToken({
    scopes: [process.env.REACT_APP_AUTHENTICATIONPARAMETERS_SCOPES],
  });
  if (token) {
    config.headers.Authorization = `Bearer ${token.accessToken}`;
  }
  return config;
});
// request interceptor without cancellation token
axiosConfigWithoutCancelToken.interceptors.request.use(async config => {
  const token = await authProvider.getAccessToken({
    scopes: [process.env.REACT_APP_AUTHENTICATIONPARAMETERS_SCOPES],
  });
  if (token) {
    config.headers.Authorization = `Bearer ${token.accessToken}`;
  }
  return config;
});

// config for marvin js (ChemAxon)
const axiosConfigForJChem = axios.create({
  ...axiosDefaults,
  timeout: 20000000,
});

axiosConfigForJChem.interceptors.request.use(async config => {
  //config.headers.Authorization = `Basic ${process.env.REACT_APP_JCHEM_TOKEN}`;
  const token = await authProvider.getAccessToken({
    scopes: [process.env.REACT_APP_AUTHENTICATIONPARAMETERS_SCOPES],
  });
  if (token) {
    config.headers.Authorization = `Bearer ${token.accessToken}`;
  }
  return config;
});

// Methods:
export const GET = axiosConfig.get;
export const POST = axiosConfig.post;
const DELETE = axiosConfig.delete;
const PUT = axiosConfig.put;
// const POSTFORMDATA = () => {axiosConfig.defaults.headers.content }

const getUrl = payload => {
  let formatUrl = `${drugsUrl}/api/${payload.apiKey}`;
  if (payload.group === 'drugs') {
    formatUrl = `${drugsUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'trials') {
    formatUrl = `${trialsUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'sites') {
    formatUrl = `${sitesUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'admin') {
    formatUrl = `${administratorUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'paging') {
    formatUrl = `${sitesUrl}/api/ancillary/${payload.apiKey}`;
  }
  if (payload.group === 'sourceDoc') {
    formatUrl = `${sourceDocumentUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'countryRegion') {
    formatUrl = `${countryUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'language') {
    formatUrl = `${registryUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'registryAbbreviationName') {
    formatUrl = `${registryUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'registryFullName') {
    formatUrl = `${registryUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'registryGovernance') {
    formatUrl = `${registryUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'information') {
    formatUrl = `${registryUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'studyType') {
    formatUrl = `${registryUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'studyPhase') {
    formatUrl = `${registryUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'interventionalType') {
    formatUrl = `${registryUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'docType') {
    formatUrl = `${sourceDocumentUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'regVersion') {
    formatUrl = `${sourceDocumentUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'requirement') {
    formatUrl = `${requirementUrl}/api/${payload.apiKey}`;
  }
  if (payload.group === 'aCTAbbrivatedName') {
    formatUrl = `${requirementUrl}/api/${payload.apiKey}`;
  }
  return formatUrl;
};

const getAgeingUrl = ({ type }) => {
  let formatUrl = '';
  if (type === ageingType.drug) {
    formatUrl = `${drugsUrl}/api/${type}`;
  }
  if (type === ageingType.trial) {
    formatUrl = `${trialsUrl}/api/${type}`;
  }
  if (type === ageingType.person) {
    formatUrl = `${sitesUrl}/api/${type}`;
  }
  if (type === ageingType.company) {
    formatUrl = `${sitesUrl}/api/${type}`;
  }
  if (type === ageingType.organization) {
    formatUrl = `${sitesUrl}/api/${type}`;
  }
  if (type === ageingType.country) {
    formatUrl = `${countryUrl}/api/${type}`;
  }
  if (type === ageingType.registry) {
    formatUrl = `${registryUrl}/api/${type}`;
  }
  if (type === ageingType.requirement) {
    formatUrl = `${requirementUrl}/api/${type}`;
  }
  if (type === ageingType.sourceDoc) {
    formatUrl = `${sourceDocumentUrl}/api/${type}`;
  }
  return formatUrl;
};

const getLocationUrl = locationType => {
  let url = '';
  switch (locationType) {
    case 'region':
      url = `${sitesUrl}/api/region`;
      break;
    case 'subRegion':
      url = `${sitesUrl}/api/SubRegion`;
      break;
    case 'country':
      url = `${sitesUrl}/api/locationcountry`;
      break;
    default:
      break;
  }
  return url;
};

const getDeletedLocationUrl = (locationType, id) => {
  let url = '';
  switch (locationType) {
    case 'region':
      url = `${sitesUrl}/api/region/${id}`;
      break;
    case 'subRegion':
      url = `${sitesUrl}/api/SubRegion/${id}`;
      break;
    case 'country':
      url = `${sitesUrl}/api/locationcountry/${id}`;
      break;
    default:
      break;
  }
  return url;
};

export const getDrugAncillaryUrl = (group, key) => {
  let url = '';
  switch (group) {
    case 'sites':
      url = `${sitesUrl}/api/${key}`;
      break;
    case 'drugs':
      url = `${drugsUrl}/api/${key}`;
      break;
    default:
      url = `${sitesUrl}/api/${key}`;
      break;
  }
  return url;
};

export const postAncillaryUrl = (group, apiKey) => {
  let url = '';
  switch (group) {
    case 'sites':
      url = `${sitesUrl}/api/ancillary/${apiKey}`;
      break;
    default:
      break;
  }
  return url;
};

export const getAuditUrl = entity => {
  let url = '';
  switch (entity) {
    case 'drugs':
      url = `${drugsUrl}/api/AuditEntry/SearchAuditEntry`;
      break;
    case 'person':
      url = `${sitesUrl}/api/PersonAuditEntry/search`;
      break;
    case 'clinicalTrial':
      url = `${trialsUrl}/api/ClinicalTrialAuditEntry/search`;
      break;
    case 'company':
      url = `${sitesUrl}/api/CompanyAuditEntry/SearchAuditEntry`;
      break;
    case 'organization':
      url = `${sitesUrl}/api/OrganizationAuditEntry/SearchAuditEntry`;
      break;
    case 'registry':
      url = `${registryUrl}/api/AuditEntry/SearchAuditEntry`;
      break;
    case 'source Documents':
      url = `${sourceDocumentUrl}/api/AuditEntry/SearchAuditEntry`;
      break;
    case 'country':
      url = `${countryUrl}/api/AuditEntry/SearchAuditEntry`;
      break;
    case 'requirement':
      url = `${requirementUrl}/api/AuditEntry/SearchAuditEntry`;
      break;
    default:
      break;
  }
  return url;
};

export const getHistoricalAuditUrl = entity => {
  let url = '';
  switch (entity) {
    case 'drugs':
      url = `${drugsUrl}/api/Drug`;
      break;
    case 'person':
      url = `${sitesUrl}/api/Person`;
      break;
    case 'clinicalTrial':
      url = `${trialsUrl}/api/ClinicalTrial`;
      break;
    case 'company':
      url = `${sitesUrl}/api/Company`;
      break;
    case 'organization':
      url = `${sitesUrl}/api/Organization`;
      break;
    case 'registry':
      url = `${registryUrl}/api/Registry`;
      break;
    case 'source Documents':
      url = `${sourceDocumentUrl}/api/SourceDoc`;
      break;
    case 'country':
      url = `${countryUrl}/api/Country`;
      break;
    case 'requirement':
      url = `${requirementUrl}/api/Requirement`;
      break;
    default:
      break;
  }
  return url;
};

const getAdvancedSearchCategoryUrl = entity => {
  let url = '';
  switch (entity) {
    case 'person':
      url = `${sitesUrl}/api/PersonAdvanceSearch/Advancesearchcategory`;
      break;
    case 'clinicalTrials':
      url = `${trialsUrl}/api/ClinicalTrialAdvanceSearch/advancedsearchcategory`;
      break;
    case 'organization':
      url = `${sitesUrl}/api/OrganizationAdvanceSearch/Advancesearchcategory`;
      break;
    case 'drugs':
      url = `${drugsUrl}/api/DrugsAdvanceSearch/advancedsearchcategory`;
      break;
    case 'company':
      url = `${sitesUrl}/api/CompanyAdvanceSearch/Advancesearchcategory`;
      break;
    case 'sourceDocument':
      url = `${sourceDocumentUrl}/api/SourceDocSearch/advancedsearchcategory`;
      break;
    case 'country':
      url = `${countryUrl}/api/CountrySearch/advancedsearchcategory`;
      break;
    case 'requirement':
      url = `${requirementUrl}/api/RequirementSearch/advancedsearchcategory`;
      break;
    case 'dashboard':
      url = `${sitesUrl}/api/PersonAdvanceSearch/Advancesearchcategory`;
      break;
    case 'registry':
      url = `${registryUrl}/api/RegistrySearch/advancedsearchcategory`;
      break;
    default:
      break;
  }
  return url;
};

const getAdvancedSearchCategoryTitleUrl = entity => {
  let url = advancedSearchUrl;
  return url;
};

const getAdvancedSearchResultsUrl = entity => {
  let url = '';
  switch (entity) {
    case 'person':
      url = `${sitesUrl}/api/PersonAdvanceSearch/elasticSearch`;
      break;
    case 'clinicalTrials':
      url = `${trialsUrl}/api/ClinicalTrialAdvanceSearch/elasticSearch`;
      break;
    case 'organization':
      url = `${sitesUrl}/api/OrganizationAdvanceSearch/elasticSearch`;
      break;
    case 'drugs':
      url = `${drugsUrl}/api/DrugsAdvanceSearch/elasticSearch`;
      break;
    case 'company':
      url = `${sitesUrl}/api/CompanyAdvanceSearch/elasticSearch`;
      break;
    case 'sourceDocument':
      url = `${sourceDocumentUrl}/api/SourceDocSearch/elasticSearch`;
      break;
    case 'registry':
      url = `${registryUrl}/api/RegistrySearch/elasticSearch`;
      break;
    case 'country':
      url = `${countryUrl}/api/CountrySearch/elasticSearch`;
      break;
    case 'requirement':
      url = `${requirementUrl}/api/RequirementSearch/elasticSearch`;
      break;
    case 'dashboard':
      url = `${administratorUrl}/api/Dashboard/GetDashboard`;
      break;
    default:
      break;
  }
  return url;
};
const getAdvancedSearchSavedSearchUrl = entity => {
  let url = '';
  switch (entity) {
    case 'person':
      url = `${sitesUrl}/api/Person/SavedSearch`;
      break;
    case 'clinicalTrials':
      url = `${trialsUrl}/api/ClinicalTrial/SavedSearch`;
      break;
    case 'organization':
      url = `${sitesUrl}/api/Organization/SavedSearch`;
      break;
    case 'drugs':
      url = `${drugsUrl}/api/Drug/SavedSearch`;
      break;
    case 'company':
      url = `${sitesUrl}/api/Company/SavedSearch`;
      break;
    case 'sourceDocument':
      url = `${sourceDocumentUrl}/api/SourceDoc/SavedSearch`;
      break;
    case 'country':
      url = `${countryUrl}/api/Country/SavedSearch`;
      break;
    case 'requirement':
      url = `${requirementUrl}/api/Requirement/SavedSearch`;
      break;
    case 'registry':
      url = `${registryUrl}/api/Registry/SavedSearch`;
      break;
    default:
      break;
  }
  return url;
};

const getAllSavedSearchesUrl = (entity, selectedUserId, searchSubTypeId) => {
  let url = '';
  switch (entity) {
    case 'person':
      url = `${sitesUrl}/api/Person/SavedSearch/user/${selectedUserId}/${searchSubTypeId}`;
      break;
    case 'clinicalTrials':
      url = `${trialsUrl}/api/ClinicalTrial/SavedSearch/user/${selectedUserId}/${searchSubTypeId}`;
      break;
    case 'organization':
      url = `${sitesUrl}/api/Organization/SavedSearch/user/${selectedUserId}/${searchSubTypeId}`;
      break;
    case 'drugs':
      url = `${drugsUrl}/api/Drug/SavedSearch/user/${selectedUserId}/${searchSubTypeId}`;
      break;
    case 'company':
      url = `${sitesUrl}/api/Company/SavedSearch/user/${selectedUserId}/${searchSubTypeId}`;
      break;
    case 'sourceDocument':
      url = `${sourceDocumentUrl}/api/SourceDoc/SavedSearch/user/${selectedUserId}/${searchSubTypeId}`;
      break;
    case 'country':
      url = `${countryUrl}/api/Country/SavedSearch/User/${selectedUserId}/${searchSubTypeId}`;
      break;
    case 'requirement':
      url = `${requirementUrl}/api/Requirement/SavedSearch/User/${selectedUserId}/${searchSubTypeId}`;
      break;
    case 'registry':
      url = `${registryUrl}/api/Registry/SavedSearch/User/${selectedUserId}/${searchSubTypeId}`;
      break;
    default:
      break;
  }
  return url;
};

export const auth = {
  getUserInfo: () => call(GET, `${administratorUrl}/api/authorization/user`),
};

export const drugs = {
  getDrugList: payload => call(POST, `${drugsUrl}/api/Drug/quickSearch`, payload),
  postDrugDissociation: payload =>
    call(POST, `${drugsUrl}/api/Drug/drug/dissociateChildDrugs`, payload),
  postDrugTransfer: (transferToDrugID, payload) =>
    call(
      POST,
      `${drugsUrl}/api/Drug/drug/TransferDrugs?transferToDrugID=${transferToDrugID}`,
      payload,
    ),
  getGlobalStatus: () => call(GET, `${drugsUrl}/api/drugGlobalStatus`),
  getCountries: () => call(GET, `${drugsUrl}/api/Country`),
  getCountryGroups: () => call(GET, `${drugsUrl}/api/CountryGroup`),
  getDrugsData: id => call(GET, `${drugsUrl}/api/drug/${id}`),
  getDPLData: payload =>
    call(axiosConfigWithoutCancelToken.post, `${drugsUrl}/api/drug/programlandscape`, payload),
  getDPLDiseases: id => call(GET, `${drugsUrl}/api/drug/programlandscapedisease/${id}`),
  getDPLCompanies: id => call(GET, `${drugsUrl}/api/drug/programlandscapecompany/${id}`),
  getDPLCountries: id => call(GET, `${drugsUrl}/api/drug/programlandscapecoutries/${id}`),
  getIngestedData: id => call(GET, `${drugsUrl}/api/drug/ingested/${id}`),
  getMasterData: () => call(GET, `${drugsUrl}/api/drug/MasterData`),
  postDrugsData: ({ id, body }) => call(POST, `${drugsUrl}/api/drug/${id}`, body),
  deleteDrug: id => call(DELETE, `${drugsUrl}/api/drug/${id}`),
  getActivityMaster: taxonomy => call(GET, `${drugsUrl}/api/${taxonomy}`),
  getEventTypes: () => call(GET, `${drugsUrl}/api/eventTypes`),
  getDrugTypes: () => call(GET, `${drugsUrl}/api/Drug/DrugTypes`),
  getNotesType: () => call(GET, `${drugsUrl}/api/NotesType`),
  getOrigin: () => call(GET, `${drugsUrl}/api/OriginOfMaterial`),
  getUserLockDetails: id => call(PUT, `${drugsUrl}/api/Drug/lockdata/${id}`),
  unlockUserRecords: id => call(POST, `${drugsUrl}/api/Drug/unlockdata/${id}`),
  getDrugAssociation: body => call(POST, `${drugsUrl}/api/Drug/associations`, body),
  getDevelopmentPhase: () => call(GET, `${drugsUrl}/api/DevelopmentPhase`),
  getDrugTrialAssociation: body =>
    call(
      POST,
      `${trialsUrl}/api/ClinicalTrial/DrugAssociation/associationSearchRequestModel`,
      body,
    ),
  getMolecularParameters: data =>
    call(axiosConfigForJChem.post, `${jchemEndpoint}/calculate/GetAllOtherDetails`, data),
  createMolecularParameters: data =>
    call(
      axiosConfigForJChem.post,
      `${jchemEndpoint}/Operation/Insert?tableName=${jchemDBTable}&isDuplicateFiltering=false`,
      data,
    ),
  getAncillaryMasterData: (group, key) => call(GET, getDrugAncillaryUrl(group, key)),
  postAncillaryData: (data, group, key) => call(POST, postAncillaryUrl(group, key), data),
  validateDPLAddition: ({ id, payload }) =>
    POST(`${drugsUrl}/api/Drug/${id}/programlandscapevalidations`, payload),
  getTrends: ({ payload }) => POST(`${drugsUrl}/api/Drug/drug/GetTrendsDataByDrugKey`, payload),
  postTrends: ({ data }) => POST(`${drugsUrl}/api/Drug/drug/InsertNewYearWithAllDetails`, data),
  deleteTrendsData: ({ payload }) => POST(`${drugsUrl}/api/Drug/DeleteAllRowData`, payload),
  deleteTrendsYearData: ({ data }) => POST(`${drugsUrl}/api/Drug/drug/DeleteYear`, data),
  postTrendsGlobalStatus: ({ data }) =>
    POST(`${drugsUrl}/api/Drug/drug/InsertHistoryDrugGlobalStatus`, data),
  postTrendsCountryStatus: ({ data }) =>
    POST(`${drugsUrl}/api/Drug/drug/InsertCountryStatus`, data),
  postTrendsTherapeutic: ({ data }) => POST(`${drugsUrl}/api/Drug/drug/InsertTherapeutic`, data),
  postTrendsLicensee: ({ data }) => POST(`${drugsUrl}/api/Drug/drug/InsertLicensee`, data),
  postTrendsDisease: ({ data }) => POST(`${drugsUrl}/api/Drug/drug/InsertDisease`, data),
};
export const ancillary = {
  getAncillaryData: (payload, isListCall) => {
    if (payload.pagination && !isListCall) {
      let requestBody = { ...payload.pagination };
      if (payload.sortRequestBody) {
        requestBody = { ...requestBody, ...payload.sortRequestBody };
      }
      if (payload.searchCriteria) {
        requestBody.searchCriteria = payload.searchCriteria;
      }
      return call(POST, `${getUrl(payload)}/quickSearch`, JSON.stringify(requestBody));
    } else {
      return call(GET, getUrl(payload, true));
    }
  },
  mergeStateData: (mergeToStateID, payload) =>
    call(POST, `${sitesUrl}/api/State/${mergeToStateID}`, payload),
  mergeCityData: (mergeToCityID, payload) =>
    call(POST, `${sitesUrl}/api/City/${mergeToCityID}`, payload),
  postAncillaryData: (taxonomy, values) =>
    call(POST, `${getUrl(taxonomy)}`, JSON.stringify(values)),
  putAncillaryData: (taxonomy, values) => call(PUT, `${getUrl(taxonomy)}`, JSON.stringify(values)),
  deleteAncillaryData: (taxonomy, values) =>
    call(DELETE, `${getUrl(taxonomy)}/${values.id || values[taxonomy.idAccessor].id}`),
  postLocationData: ({ locationType, body }) => call(POST, getLocationUrl(locationType), body),
  putLocationData: ({ locationType, body }) => call(PUT, getLocationUrl(locationType), body),
  deleteLocationData: ({ locationType, id }) =>
    call(DELETE, getDeletedLocationUrl(locationType, id)),
  getLocationData: () => call(GET, `${sitesUrl}/api/Region/GetAllRecords`),
  getAncillaryCompany: id => call(GET, `${sitesUrl}/api/ancillary/company/${id}`),
  getAncillaryRecordById: (type, id) => call(GET, `${trialsUrl}/api/${type}/${id}`),
};

export const trials = {
  getMasterData: () => call(GET, `${trialsUrl}/api/ClinicalTrial/Masters`),
  getTrialNotesAndResults: (isTrialResult, payloadData) =>
    call(
      POST,
      `${trialsUrl}/api/ClinicalTrial/Trial/${
        isTrialResult ? 'GetPaginatedResultsByTrialID' : 'GetPaginatedNotesByTrialID'
      }`,
      payloadData,
    ),
  getTrialAssociations: ({ payload }) =>
    call(
      POST,
      `${trialsUrl}/api/ClinicalTrial/TrialAssociation/associationSearchRequestModel`,
      payload,
    ),
  getAllCountries: () => call(GET, `${sitesUrl}/api/LocationCountry/GetAllCountries`),
  getAllRegions: () => call(GET, `${sitesUrl}/api/LocationCountry/GetAllRegions`),
  getAllSubRegions: () => call(GET, `${sitesUrl}/api/LocationCountry/GetAllSubRegions`),
  getTrialsData: id => call(GET, `${trialsUrl}/api/ClinicalTrial/${id}`),
  getIngestedData: id => call(GET, `${trialsUrl}/api/ClinicalTrial/Ingested/${id}`),
  saveTrialsData: request => call(POST, `${trialsUrl}/api/ClinicalTrial`, { ...request }),
  getTrialList: ({ payload }) => call(POST, `${trialsUrl}/api/ClinicalTrial/quickSearch`, payload),
  deleteTrialData: id => call(DELETE, `${trialsUrl}/api/ClinicalTrial/${id}`),
  searchOrganizationData: body => call(POST, `${sitesUrl}/api/organization/search`, body),
  searchPersonData: body => call(POST, `${sitesUrl}/api/person/search`, body),
  searchTrialResults: body => call(POST, `${trialsUrl}/api/ClinicalTrial/resultsearch`, body),
  searchTrialNotes: body => call(POST, `${trialsUrl}/api/ClinicalTrial/notesearch`, body),
  getUserLockDetails: id => call(PUT, `${trialsUrl}/api/ClinicalTrial/lockdata/${id}`),
  unlockUserRecords: id => call(POST, `${trialsUrl}/api/ClinicalTrial/unlockdata/${id}`),
  getSponsorType: () => call(GET, `${trialsUrl}/api/sponsorType`),
  validateContactAddition: ({ id, payload }) =>
    GET(
      `${trialsUrl}/api/ClinicalTrial/isTrialAssociationCombinationExists/${id}/${payload.orgID}/${payload.personID}/${payload.roleID}`,
    ),
  validateMultipleContactAddition: ({ payload }) =>
    POST(`${trialsUrl}/api/ClinicalTrial/isTrialAssociationCombinationExistsMultiple`, payload),
};

export const administrator = {
  getRoleGroup: () => call(GET, `${administratorUrl}/api/Processor/GetAll/true`),
  saveRole: payload => call(POST, `${administratorUrl}/api/Role`, payload),
  getRoles: () => call(GET, `${administratorUrl}/api/Role`),
  getRoleById: roleId => call(GET, `${administratorUrl}/api/Role/${roleId}`),
  getRolesPermission: () => call(GET, `${administratorUrl}/api/Role/GetPermissionGroup`),
  searchRoles: payload => call(GET, `${administratorUrl}/api/Role/${payload}`),
  deleteRole: roleId => call(DELETE, `${administratorUrl}/api/Role/${roleId}`),
  getAdminData: () => call(GET, `${administratorUrl}/api/DataSourceGroup`),
  postAdminData: payload => call(POST, `${administratorUrl}/api/DataSourceGroup`, payload.data),
  putAdminData: payload => call(PUT, `${administratorUrl}/api/DataSourceGroup`, payload.data),
  deleteAdminData: payload => call(DELETE, `${administratorUrl}/api/DataSourceGroup/${payload.id}`),
  getUsers: () => call(GET, `${administratorUrl}/api/User/UserRole/true`),
  searchUsers: params => call(POST, `${administratorUrl}/api/User/Search`, params),
  saveAssignRoles: payload => call(POST, `${administratorUrl}/api/User/AssignRole`, payload),
  getDataSource: () => call(GET, `${administratorUrl}/api/DataSource`),
  postDataSource: payload => call(POST, `${administratorUrl}/api/DataSource`, payload),
  putDataSource: payload => call(PUT, `${administratorUrl}/api/DataSource`, payload),
  deleteDataSource: (id, status) =>
    call(DELETE, `${administratorUrl}/api/DataSource/${id}/${status}`),
  getProcessor: () => call(GET, `${administratorUrl}/api/Processor/GetAll/true`),
  deleteProcessor: processorId => call(DELETE, `${administratorUrl}/api/Processor/${processorId}`),
  searchProcessor: payload => call(GET, `${administratorUrl}/api/Processor/name/${payload}/true`),
  saveProcessor: payload => call(POST, `${administratorUrl}/api/Processor`, payload),
  putProcessor: payload => call(PUT, `${administratorUrl}/api/Processor`, payload),
  getAgeing: ({ data, type }) => call(GET, `${getAgeingUrl({ data, type })}`),
  putAgeing: ({ data, type }) => call(PUT, `${getAgeingUrl({ data, type })}`, data),
  getUserGroup: () => call(GET, `${administratorUrl}/api/UserGroup`),
  deleteUserGroup: id => call(DELETE, `${administratorUrl}/api/UserGroup/${id}`),
  searchUserGroup: payload => call(GET, `${administratorUrl}/api/UserGroup/name/${payload}`),
  saveUserGroup: payload => call(POST, `${administratorUrl}/api/UserGroup`, payload),
  putUserGroup: payload => call(PUT, `${administratorUrl}/api/UserGroup`, payload),
  getQueue: () => call(GET, `${administratorUrl}/api/Queue`),
  putQueue: payload => call(PUT, `${administratorUrl}/api/Queue`, payload),
  getQueueMaster: () => call(GET, `${administratorUrl}/api/Queue/Masters`),
  saveAssignUserGroups: payload =>
    call(POST, `${administratorUrl}/api/User/userGroupAssignOrUnassign`, payload),
};

//Organization
export const organization = {
  //temporary urls for landing page
  getOrganizationList: payload => call(POST, `${sitesUrl}/api/Organization/quickSearch`, payload),
  deleteOrganization: id => call(DELETE, `${sitesUrl}/api/Organization/${id}`),
  //edit urls for organization
  getMasterData: () => call(GET, `${sitesUrl}/api/Organization/masterdata`),
  fetchOrganizationFeature: id => call(GET, `${sitesUrl}/api/Organization/${id}`),
  postOrganizationData: payload => call(POST, `${sitesUrl}/api/Organization`, payload),
  getCountries: () => call(GET, `${countries}`),
  getOrganizationTree: id => call(GET, `${sitesUrl}/api/Organization/${id}/Tree`),
  validateAddress: payload =>
    call(POST, `${sitesUrl}/api/Organization/ValidateAddressByAWSLocationService`, payload),
  getIngestedData: id => call(GET, `${sitesUrl}/api/Organization/ingested/${id}`),
  getUserLockDetails: id => call(PUT, `${sitesUrl}/api/Organization/lockdata/${id}`),
  unlockUserRecords: id => call(POST, `${sitesUrl}/api/Organization/unlockdata/${id}`),
  searchOrganizationNoteType: payload =>
    call(POST, `${sitesUrl}/api/Organization/searchnotes`, payload),
  getCurrentAssociatedPeople: body =>
    call(
      POST,
      `${trialsUrl}/api/ClinicalTrial/OrgPersonAssociation/associationSearchRequestModel`,
      body,
    ),
  getOngoingAssociatedTrial: body =>
    call(
      POST,
      `${trialsUrl}/api/ClinicalTrial/OrgAssociationOnGoing/associationSearchRequestModel`,
      body,
    ),
  getAllAssociatedTrial: body =>
    call(
      POST,
      `${trialsUrl}/api/ClinicalTrial/OrgAssociationAll/associationSearchRequestModel`,
      body,
    ),
};

export const person = {
  getPersonList: payload => call(POST, `${sitesUrl}/api/Person/quickSearch`, payload),
  deletePerson: id => call(DELETE, `${sitesUrl}/api/person/${id}`),
  getPersonDetails: id => call(GET, `${sitesUrl}/api/person/${id}`),
  getIngestedData: id => call(GET, `${sitesUrl}/api/person/ingested/${id}`),
  getPersonMasterData: () => call(GET, `${sitesUrl}/api/person/masterdata`),
  savePersonDetails: body => call(POST, `${sitesUrl}/api/person`, body),
  getUserLockDetails: id => call(PUT, `${sitesUrl}/api/Person/lockdata/${id}`),
  unlockUserRecords: id => call(POST, `${sitesUrl}/api/Person/unlockdata/${id}`),
  searchPersonNoteType: payload => call(POST, `${sitesUrl}/api/Person/searchnotes`, payload),
  getPersonAssociation: body =>
    call(
      POST,
      `${trialsUrl}/api/ClinicalTrial/PersonAssociation/associationSearchRequestModel`,
      body,
    ),
  getPersonOrgAssociation: body =>
    call(
      POST,
      `${trialsUrl}/api/ClinicalTrial/PersonOrgAssociation/associationSearchRequestModel`,
      body,
    ),
};

export const company = {
  getCompanyList: ({ payload }) => call(POST, `${sitesUrl}/api/company/quickSearch`, payload),
  validateBeforeDelete: id => call(POST, `${sitesUrl}/api/company/ValidateBeforeDelete?id=${id}`),
  deleteCompany: id => call(DELETE, `${sitesUrl}/api/company/${id}`),
  getCountries: () => call(GET, `${countries}`),
  getStateDataByCountryId: id => call(GET, `${sitesUrl}/api/State/Country/${id}`),
  getMasterData: () => call(GET, `${sitesUrl}/api/company/masterdata`),
  getCompanyDataById: id => call(GET, `${sitesUrl}/api/company/${id}`),
  getIngestedData: id => call(GET, `${sitesUrl}/api/company/ingested/${id}`),
  saveCompanyDetails: body => call(POST, `${sitesUrl}/api/company`, body),
  getUserLockDetails: id => call(PUT, `${sitesUrl}/api/Company/lockdata/${id}`),
  unlockUserRecords: id => call(POST, `${sitesUrl}/api/Company/unlockdata/${id}`),
  getCompanyAssociationDetails: body =>
    call(POST, `${drugsUrl}/api/drug/companyassociations`, body),
  getTrialDetailsBasedOnDrug: body =>
    call(
      POST,
      `${trialsUrl}/api/clinicalTrial/drugAssociation/associationSearchRequestModel`,
      body,
    ),
};

export const sourceDocument = {
  getSourceDocumentList: payload =>
    call(POST, `${sourceDocumentUrl}/api/SourceDoc/quickSearch`, payload),
  deleteSourceDocument: (id, prodStatus) =>
    call(
      DELETE,
      prodStatus === 3
        ? `${sourceDocumentUrl}/api/SourceDoc/Prod/${id}`
        : `${sourceDocumentUrl}/api/SourceDoc/${id}`,
    ),
  getSourceDocumentDetails: id => call(GET, `${sourceDocumentUrl}/api/SourceDoc/${id}`),
  getSourceDocumentMasterData: () => call(GET, `${sourceDocumentUrl}/api/SourceDoc/Masters`),
  saveSourceDocumentDetails: body => call(POST, `${sourceDocumentUrl}/api/SourceDoc`, body),
  getUserLockDetails: id => call(PUT, `${sourceDocumentUrl}/api/SourceDoc/lockdata/${id}`),
  unlockUserRecords: id => call(POST, `${sourceDocumentUrl}/api/SourceDoc/unlockdata/${id}`),

  saveUploadFiles: (body, fileName) =>
    call(
      axiosConfigWithFormData.post,
      `${sourceDocumentUrl}/api/SourceDoc/UploadFiles?key=${fileName}.pdf`,
      body,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    ),
  getSDStageRecordId: id => call(GET, `${sourceDocumentUrl}/api/SourceDoc/GetStagRecordId/${id}`),
  getSDRecordChangeFieldsList: id =>
    call(GET, `${sourceDocumentUrl}/api/SourceDoc/GetChangeDetailsById/${id}`),
  getStageRecordDetails: id =>
    call(GET, `${sourceDocumentUrl}/api/SourceDoc/GetStageRecordDetails/${id}`),
};

export const country = {
  getCountryList: payload => call(POST, `${countryUrl}/api/Country/quickSearch`, payload),
  deleteCountry: (id, prodStatus) =>
    call(
      DELETE,
      prodStatus === 3 ? `${countryUrl}/api/Country/Prod/${id}` : `${countryUrl}/api/Country/${id}`,
    ),
  getCountryDetails: id => call(GET, `${countryUrl}/api/Country/${id}`),
  getCountryMasterData: () => call(GET, `${countryUrl}/api/Country/Masters`),
  saveCountryDetails: body => call(POST, `${countryUrl}/api/Country`, body),
  getUserLockDetails: id => call(PUT, `${countryUrl}/api/Country/lockdata/${id}`),
  unlockUserRecords: id => call(POST, `${countryUrl}/api/Country/unlockdata/${id}`),
  getStageRecordId: id => call(GET, `${countryUrl}/api/Country/GetStagRecordId/${id}`),
  getCountryRecordChangeFieldsList: id =>
    call(GET, `${countryUrl}/api/Country/GetChangeDetailsById/${id}`),
};
export const requirement = {
  getRequirementList: payload =>
    call(POST, `${requirementUrl}/api/Requirement/quickSearch`, payload),
  deleteRequirement: id => call(DELETE, `${requirementUrl}/api/Requirement/${id}`),
  getRequirementDetails: id => call(GET, `${requirementUrl}/api/Requirement/${id}`),
  getRequirementMasterData: () => call(GET, `${requirementUrl}/api/Requirement/Masters`),
  saveRequirementDetails: body => call(POST, `${requirementUrl}/api/Requirement`, body),
  getUserLockDetails: id => call(PUT, `${requirementUrl}/api/Requirement/lockdata/${id}`),
  unlockUserRecords: id => call(POST, `${requirementUrl}/api/Requirement/unlockdata/${id}`),
  getReqStageRecordId: id => call(GET, `${requirementUrl}/api/Requirement/GetStagRecordId/${id}`),
  getReqRecordChangeFieldsList: id =>
    call(GET, `${requirementUrl}/api/Requirement/GetChangeDetailsById/${id}`),
};

export const registry = {
  getRegistryList: payload => call(POST, `${registryUrl}/api/Registry/quickSearch`, payload),
  deleteRegistry: (id, prodStatus) =>
    call(
      DELETE,
      prodStatus === 3
        ? `${registryUrl}/api/Registry/Prod/${id}`
        : `${registryUrl}/api/Registry/${id}`,
    ),
  getRegistryDetails: id => call(GET, `${registryUrl}/api/Registry/${id}`),
  getRegistryMasterData: () => call(GET, `${registryUrl}/api/Registry/Masters`),
  saveRegistryDetails: body => call(POST, `${registryUrl}/api/Registry`, body),
  getUserLockDetails: id => call(PUT, `${registryUrl}/api/Registry/lockdata/${id}`),
  unlockUserRecords: id => call(POST, `${registryUrl}/api/Registry/unlockdata/${id}`),
  getStagedIdRegistry: id => call(GET, `${registryUrl}/api/Registry/GetStagRecordId/${id}`),
  getRegRecordChangeFieldsList: id =>
    call(GET, `${registryUrl}/api/Registry/GetChangeDetailsById/${id}`),
};

export const queues = {
  getQueueRecords: payload =>
    call(POST, `${administratorUrl}/api/QueueDashboard/QueueViewList/Trial`, payload),
  getDrugQueueRecords: payload =>
    call(POST, `${administratorUrl}/api/QueueDashboard/QueueViewList/Drugs`, payload),
  getPersonQueueRecords: payload =>
    call(POST, `${administratorUrl}/api/QueueDashboard/QueueViewList/Person`, payload),
  getOrganizationQueueRecords: payload =>
    call(POST, `${administratorUrl}/api/QueueDashboard/QueueViewList/Organization`, payload),
  getCompanyQueueRecords: payload =>
    call(POST, `${administratorUrl}/api/QueueDashboard/QueueViewList/Company`, payload),
  getQueueTypes: entityTypeId =>
    call(GET, `${administratorUrl}/api/QueueDashboard/QueueViewList/HierarchyView/${entityTypeId}`),
  postQueueReferenceRecord: payload =>
    call(POST, `${administratorUrl}/api/QueueTransaction/QueueReferenceRecord`, payload),
  postExitQueue: payload =>
    call(POST, `${administratorUrl}/api/QueueTransaction/ExitQueue`, payload),
};
export const reporting = {
  getSourceURLReport: payload => call(GET, `${reportingUrl}SupportingUrlReport/${payload}`),
  getDiseasingReport: payload => call(GET, `${reportingUrl}DiseaseStatisticsReport/${payload}`),
  getAgingReport: payload => call(GET, `${reportingUrl}AgeingStatisticsReport/${payload}`),
};
export const task = {
  getTaskMaster: () => call(GET, `${administratorUrl}/api/Tasks/GetMasterData`),
  getTaskQueueRecords: payload =>
    call(POST, `${administratorUrl}/api/TasksDashboard/TasksViewList/Trial`, payload),
  getTaskDrugQueueRecords: payload =>
    call(POST, `${administratorUrl}/api/TasksDashboard/TasksViewList/Drugs`, payload),
  getTaskCompanyQueueRecords: payload =>
    call(POST, `${administratorUrl}/api/TasksDashboard/TasksViewList/Company`, payload),
  getTaskPersonQueueRecords: payload =>
    call(POST, `${administratorUrl}/api/TasksDashboard/TasksViewList/Person`, payload),
  getTaskOrganizationQueueRecords: payload =>
    call(POST, `${administratorUrl}/api/TasksDashboard/TasksViewList/Organization`, payload),
  getTaskQueueTypes: entityTypeId =>
    call(GET, `${administratorUrl}/api/TasksDashboard/TasksViewList/HierarchyView/${entityTypeId}`),
  assigneeUserGroupList: payload =>
    call(POST, `${administratorUrl}/api/UserGroup/GetUsersAndUserGroupByQueueId`, payload),
  saveCreateTask: payload => call(POST, `${administratorUrl}/api/Tasks`, payload),
  deleteTask: payload => call(DELETE, `${administratorUrl}/api/Tasks/${payload.id}`),
  editTask: payload => call(PUT, `${administratorUrl}/api/Tasks/${payload.id}`),
  getAdvancedSearchTaskList: payload =>
    call(POST, `${administratorUrl}/api/Tasks/quickSearch`, payload),
  postAssigneeGroupDetails: body =>
    call(POST, `${administratorUrl}/api/QueueTransaction/Reassign`, body),
  getUserGroupsList: () => call(GET, `${administratorUrl}/api/UserGroup/GetCrossTeamUserGroups`),
  getCrossTeamRecordLatestComment: (recordId, queueId) =>
    call(
      GET,
      `${administratorUrl}/api/QueueTransactionComment/GetCrossTeamRecordLatestComment/${recordId}/${queueId}`,
    ),
  postCrossAlertData: payload =>
    call(POST, `${administratorUrl}/api/QueueTransaction/SendCrossTeamAlert`, payload),
  postAncillaryWorkFlowRequest: payload =>
    call(POST, `${administratorUrl}/api/AdditionalWorkflow`, payload),
  getAncillaryRequestsData: () => call(GET, `${administratorUrl}/api/AdditionalWorkflow`),
  updateAncillaryRequestStatus: payload =>
    call(PUT, `${administratorUrl}/api/AdditionalWorkflow`, payload),
};

export const advancedsearch = {
  getAdvancedSearchCategoryData: selectedEntity =>
    call(GET, getAdvancedSearchCategoryUrl(selectedEntity)),
  getAdvancedSearchCategoryTitleData: (selectedEntity, endPoint) =>
    call(GET, `${getAdvancedSearchCategoryTitleUrl(selectedEntity)}${endPoint}`),
  getAllSavedSearches: (selectedEntity, selectedUserId, searchSubTypeId) =>
    call(GET, getAllSavedSearchesUrl(selectedEntity, selectedUserId, searchSubTypeId)),
  addSavedSearch: (selectedEntity, payload) =>
    call(POST, getAdvancedSearchSavedSearchUrl(selectedEntity), payload),
  editSavedSearch: (selectedEntity, payload) =>
    call(PUT, `${getAdvancedSearchSavedSearchUrl(selectedEntity)}`, payload),
  deleteSavedSearch: (selectedEntity, id) =>
    call(DELETE, `${getAdvancedSearchSavedSearchUrl(selectedEntity)}/${id}`),
  getSearchResults: (selectedEntity, payload) =>
    call(POST, getAdvancedSearchResultsUrl(selectedEntity), payload),
  getEntityColDef: ({ entityId, isQuickSearch }) =>
    call(GET, `${administratorUrl}/api/Search/${entityId}/${isQuickSearch}`),
};

export const globalAudit = {
  getAuditData: (entity, payload) => call(POST, getAuditUrl(entity), payload),
  getHistoricalAuditData: (entity, id) =>
    call(GET, `${getHistoricalAuditUrl(entity)}/AuditTrialHistory/${id}`),
};

export const getTypeaheadSearchUrls = (type, urlKey) => {
  let searchUrl = '';
  switch (type) {
    case 'trials':
      searchUrl = `${trialsUrl}/api/${urlKey}/keywordsearch`;
      break;
    case 'drugs':
      searchUrl = `${drugsUrl}/api/${urlKey}/keywordsearch`;
      break;
    case 'sites':
      searchUrl = `${sitesUrl}/api/${urlKey}/keywordsearch`;
      break;
    case 'registry':
      searchUrl = `${registryUrl}/api/${urlKey}/keywordsearch`;
      break;
    case 'SourceDoc':
      searchUrl = `${sourceDocumentUrl}/api/${urlKey}/keywordsearch`;
      break;
    case 'personAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'clinicalTrialsAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'organizationAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'drugsAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'companyAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'personSavedSearchUsers':
      searchUrl = `${sitesUrl}/api/person/SavedSearch/usernamesearch`;
      break;
    case 'clinicalTrialsSavedSearchUsers':
      searchUrl = `${trialsUrl}/api/ClinicalTrial/SavedSearch/usernamesearch`;
      break;
    case 'organizationSavedSearchUsers':
      searchUrl = `${sitesUrl}/api/organization/SavedSearch/usernamesearch`;
      break;
    case 'drugsSavedSearchUsers':
      searchUrl = `${drugsUrl}/api/drug/SavedSearch/usernamesearch`;
      break;
    case 'companySavedSearchUsers':
      searchUrl = `${sitesUrl}/api/Company/SavedSearch/usernamesearch`;
      break;
    case 'admin':
      searchUrl = `${administratorUrl}/api/${urlKey}/keywordsearch`;
      break;
    case 'country':
      searchUrl = `${countryUrl}/api/${urlKey}/keywordsearch`;
      break;
    case 'requirement':
      searchUrl = `${requirementUrl}/api/${urlKey}/keywordsearch`;
      break;
    case 'countryMaster':
      searchUrl = `${countryUrl}/api/${urlKey}`;
      break;
    case 'ReqGetStudyPhasesOrderById':
      searchUrl = `${requirementUrl}/api/Requirement/GetStudyPhasesOrderById`;
      break;
    case 'RequirementLinkedWithCountry':
      searchUrl = `${requirementUrl}/api/Requirement/GetRequirementsLinkedWithCountry/${urlKey}`;
      break;
    default:
      break;
  }
  return searchUrl;
};

export const getTypeaheadSearchUrlsForQuickAndAdvancedSearch = (searchSubTypeId, type, urlKey) => {
  let searchUrl = '';
  switch (type) {
    case 'personAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'clinicalTrialsAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'organizationAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'drugsAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'companyAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'person':
      searchUrl = `${sitesUrl}/api/person/SavedSearch/usernamesearch/${searchSubTypeId}`;
      break;
    case 'clinicalTrials':
      searchUrl = `${trialsUrl}/api/ClinicalTrial/SavedSearch/usernamesearch/${searchSubTypeId}`;
      break;
    case 'organization':
      searchUrl = `${sitesUrl}/api/organization/SavedSearch/usernamesearch/${searchSubTypeId}`;
      break;
    case 'drugs':
      searchUrl = `${drugsUrl}/api/drug/SavedSearch/usernamesearch/${searchSubTypeId}`;
      break;
    case 'company':
      searchUrl = `${sitesUrl}/api/Company/SavedSearch/usernamesearch/${searchSubTypeId}`;
      break;
    case 'sourceDocument':
      searchUrl = `${sourceDocumentUrl}/api/SourceDoc/SavedSearch/userNameSearch/${searchSubTypeId}`;
      break;
    case 'sourceDocumentAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'country':
      searchUrl = `${countryUrl}/api/Country/SavedSearch/userNameSearch/${searchSubTypeId}`;
      break;
    case 'registry':
      searchUrl = `${registryUrl}/api/Registry/SavedSearch/userNameSearch/${searchSubTypeId}`;
      break;
    case 'registryAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'countryAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    case 'requirement':
      searchUrl = `${requirementUrl}/api/Requirement/SavedSearch/userNameSearch/${searchSubTypeId}`;
      break;
    case 'requirementAdvancedSearch':
      searchUrl = `${urlKey}`;
      break;
    default:
      break;
  }
  return searchUrl;
};

export default { drugs };
