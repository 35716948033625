/* eslint-disable generator-star-spacing */
import React from 'react';
import { takeEvery, put, select, all } from 'redux-saga/effects';
import * as sourceDocumentAction from '../actions/sourceDocuments';
import * as globalActions from '../actions/globalActions';
import * as advancedSearchActions from '../actions/advancedSearch';
import { sourceDocument as sourceDocumentApi } from '../../api';
import { ALERT_MESSAGES, MODAL_TYPES, SOURCEDOCUMENT_TYPES } from '../../utils/generic/constants';
import {
  getLockMessageInfo,
  getApiErrorMessage,
  formatSourceDocPayload,
} from '../../utils/generic/helper';

export function* getSourceDocumentListAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield sourceDocumentApi.getSourceDocumentList(payload);

    const { state, data } = response.data;
    if (state) {
      yield put(sourceDocumentAction.getSourceDocumentListSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(sourceDocumentAction.setError());
  }
}

export function* deleteSourceDocumentAsync({
  payload: { sourceDocumentId, postParam, productionStatusId },
}) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield sourceDocumentApi.deleteSourceDocument(
      sourceDocumentId,
      productionStatusId,
    );
    yield put(globalActions.hideSpinner());
    const {
      data: { state, messages },
    } = response;

    if (state) {
      // yield put(sourceDocumentAction.getSourceDocumentList(postParam));
      yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_DELETE));
      window.location.reload();
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.SOURCEDOCUMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}

export function* saveSourceDocumentAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.sourceDocuments);
    const { id = 0, sourceName = null } = original;
    const forceUpdate = [SOURCEDOCUMENT_TYPES[Object.keys(payload)[0]]];
    const response = yield sourceDocumentApi.saveSourceDocumentDetails({
      id,
      forceUpdate,
      sourceName,
      ...payload,
    });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(sourceDocumentAction.saveSourceDocumentDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_SAVE));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.SOURCEDOCUMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(sourceDocumentAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_ERROR));
  }
}

export function* saveSourceDocumentsAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.sourceDocuments);
    const { id = 0 } = original;
    // const{fileName,formData} = formData
    const forceUpdate = [SOURCEDOCUMENT_TYPES[Object.keys(payload)[0]]];
    const response = yield sourceDocumentApi.saveUploadFiles(payload.formData, payload.fileName);
    // const { state, data, messages } = response.data;
    // if (state) {
    //   yield put(sourceDocumentAction.saveSourceDocumentDetailsSuccess(data));
    //   yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_SAVE));
    // } else {
    //   const modalData = {
    //     isOpen: true,
    //     message:
    //       messages && messages.length
    //         ? messages[0].errorMessage
    //         : ALERT_MESSAGES.SOURCEDOCUMENT_ERROR.message,
    //     modalType: MODAL_TYPES.ERROR,
    //   };
    //   yield put(globalActions.showModal(modalData));
    // }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(sourceDocumentAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_ERROR));
  }
}

export function* saveSourceDocumentDownloadAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.sourceDocuments);
    const { id = 0 } = original;
    const forceUpdate = [SOURCEDOCUMENT_TYPES[Object.keys(payload)[0]]];
    const response = yield sourceDocumentApi.saveDownlaodFiles(payload.formData);
    const { state, data, messages } = response.data;
    if (state) {
      yield put(sourceDocumentAction.saveSourceDocumentDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_SAVE));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.SOURCEDOCUMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(sourceDocumentAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_ERROR));
  }
}

export function* getSourceDocumentMasterDataAsync() {
  try {
    yield put(globalActions.showSpinner());

    const response = yield sourceDocumentApi.getSourceDocumentMasterData();

    const { state, data } = response.data;
    if (state) {
      yield put(sourceDocumentAction.getSourceDocumentMasterDataSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(sourceDocumentAction.setError());
  }
}

export function* getSourceDocumentAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { id } = payload;
    const sourceDocDetails = yield sourceDocumentApi.getSourceDocumentDetails(id);
    if (sourceDocDetails) {
      const { state, data, messages } = sourceDocDetails.data;
      if (state) {
        yield put(sourceDocumentAction.getSourceDocumentDetailsSuccess(data));
      }
      if (data?.productionStatus !== 2) {
        const lockDetails = yield sourceDocumentApi.getUserLockDetails(id);
        if (lockDetails) {
          const {
            data: { state, data, messages },
          } = lockDetails;
          if (state) {
            yield put(sourceDocumentAction.getSourceDocumentUserLockDetails(data));
            const { isEditable, lockedBy } = data;
            if (!isEditable) {
              yield put(
                globalActions.showModal({
                  isOpen: true,
                  message: getLockMessageInfo(lockedBy),
                  modalType: MODAL_TYPES.INFO,
                }),
              );
            }
          } else {
            yield put(
              globalActions.showModal({
                isOpen: true,
                message: getApiErrorMessage(messages),
                modalType: MODAL_TYPES.ERROR,
              }),
            );
          }
        }
      }
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(sourceDocumentAction.setError());
  }
}

export function* publishSourceDocAsync({ payload }) {
  const { id, auditProperties } = payload;
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.sourceDocuments);
    const { sourceDocuments = null } = original;
    const forceUpdate = [3];
    const productionStatus = 2;
    const response = yield sourceDocumentApi.saveSourceDocumentDetails({
      id,
      forceUpdate,
      sourceDocuments,
      productionStatus,
      auditProperties,
    });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(sourceDocumentAction.saveSourceDocumentDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_PUBLISHED));
      if (auditProperties) {
        window.location.href = '/dashboard';
      } else {
        window.location.reload();
      }
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.SOURCEDOCUMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(sourceDocumentAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_ERROR));
  }
}

export function* validateSourceDocAsync() {
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.sourceDocuments);
    const { id = 0, sourceName = null } = original;
    const forceUpdate = [3];
    const productionStatus = 1;
    const response = yield sourceDocumentApi.saveSourceDocumentDetails({
      id,
      forceUpdate,
      sourceName,
      productionStatus,
    });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(sourceDocumentAction.saveSourceDocumentDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_VALIDATE));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.SOURCEDOCUMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(sourceDocumentAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_ERROR));
  }
}

export function* moveOutofScopeSourceDocAsync() {
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.sourceDocuments);
    const { id = 0 } = original;
    const forceUpdate = [3];
    const productionStatus = 3;
    const response = yield sourceDocumentApi.saveSourceDocumentDetails({
      id,
      forceUpdate,
      productionStatus,
    });
    const { state, data, messages } = response.data;
    const delay = ms => new Promise(res => setTimeout(res, ms));
    if (state) {
      put(sourceDocumentAction.saveSourceDocumentDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_OUTOFSCOPE));
      yield delay(1000);
      window.location.reload();
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.SOURCEDOCUMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(sourceDocumentAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_ERROR));
  }
}

export function* getStagedIdSourceDocAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const id = payload;
    const response = yield sourceDocumentApi.getSDStageRecordId(id);
    const { state, data, messages } = response.data;
    if (state) {
      yield put(sourceDocumentAction.getStagedIdSourceDocSuccess(data));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.SOURCEDOCUMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(sourceDocumentAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_ERROR));
  }
}

export function* getSourceDocRecordChangeFldsListAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { id } = payload;
    const response = yield sourceDocumentApi.getSDRecordChangeFieldsList(id);

    //const { state, data } = response.data;
    if (response.status) {
      yield put(sourceDocumentAction.sourceDocRecordChangeFldsListSuccess(response.data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(sourceDocumentAction.setError());
  }
}

export function* getSourceDocumentStampingDetailsAsync({ payload: { body } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield sourceDocumentApi.saveSourceDocumentDetails(body);
    const { state, data } = response.data;
    if (state) {
      yield put(sourceDocumentAction.getSourceDocumentStampingDetailsSuccess({ data }));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(sourceDocumentAction.setError());
  }
}

export function* releaseSourceDocumentUserLockAsync({ payload }) {
  try {
    yield sourceDocumentApi.unlockUserRecords(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* updateSourceDocumentLockDetailsAsync({ payload }) {
  try {
    yield sourceDocumentApi.getUserLockDetails(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* getSrcDocCompareDataAsync({ payload: { selectedIds, isIngestedId } }) {
  try {
    yield put(globalActions.showSpinner());
    const selectedQueueAPI = sourceDocumentApi.getSourceDocumentDetails;
    const [idOneDetails, idTwoDetails, idThreeDetails, idFourDetails] = yield all([
      selectedQueueAPI(selectedIds[0]),
      selectedQueueAPI(selectedIds[1]),
      selectedIds[2] ? selectedQueueAPI(selectedIds[2]) : '',
      selectedIds[3] ? selectedQueueAPI(selectedIds[3]) : '',
    ]);
    if (idOneDetails && idTwoDetails && idThreeDetails && idFourDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      const { data: idThreeData, state: idThreeState } = idThreeDetails.data;
      const { data: idFourData, state: idFourState } = idFourDetails.data;
      if (idOneState && idTwoState && idThreeState && idFourState) {
        yield put(
          sourceDocumentAction.getSrcDocCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
            compareIdThree: idThreeData,
            compareIdFour: idFourData,
          }),
        );
      }
    } else if (idOneDetails && idTwoDetails && idThreeDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      const { data: idThreeData, state: idThreeState } = idThreeDetails.data;
      if (idOneState && idTwoState && idThreeState) {
        yield put(
          sourceDocumentAction.getSrcDocCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
            compareIdThree: idThreeData,
          }),
        );
      }
    } else if (idOneDetails && idTwoDetails) {
      const { data: idOneData, state: idOneState } = idOneDetails.data;
      const { data: idTwoData, state: idTwoState } = idTwoDetails.data;
      if (idOneState && idTwoState) {
        yield put(
          sourceDocumentAction.getSrcDocCompareDataSuccess({
            compareIdOne: idOneData,
            compareIdTwo: idTwoData,
          }),
        );
      }
    } else {
      yield put(
        globalActions.showModal({
          isOpen: true,
          message: 'Something went wrong',
          modalType: MODAL_TYPES.ERROR,
        }),
      );
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
    yield put(globalActions.hideSpinner());
  }
}

export function* sourceDocBulkLinkingAsync({ payload: { selectedIds, selectedData } }) {
  try {
    yield put(globalActions.showSpinner());
    const multipleStageIds = selectedIds.map(id => sourceDocumentApi.getStageRecordDetails(id));
    const response = yield all(multipleStageIds);
    let results = response.map(obj => obj.data);
    if (results.some(obj => !obj.state)) {
      yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    } else {
      yield put(sourceDocumentAction.sourceDocBulkLinkingSuccess({ results }));
      const multipleSaveIds = results?.map(({ data }) =>
        sourceDocumentApi.saveSourceDocumentDetails({
          id: data?.id,
          forceUpdate: [2],
          sourceName: null,
          sourceDocumentModel: formatSourceDocPayload(data?.sourceDocumentModel, selectedData),
          disableCancel: true,
        }),
      );
      const response = yield all(multipleSaveIds);
      let saveResults = response.map(obj => obj.data);
      if (saveResults.some(obj => !obj.state)) {
        yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
      } else {
        yield put(globalActions.showAlert(ALERT_MESSAGES.MULTI_SOURCEDOCUMENT_SAVE));
      }
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* sourceDocBulkValidateAsync({ payload: { validIds, errorIds, postParam } }) {
  try {
    yield put(globalActions.showSpinner());
    if (validIds.length) {
      const productionStatus = 1;
      const forceUpdate = [3];
      const multipleValidateIds = validIds.map(id =>
        sourceDocumentApi.saveSourceDocumentDetails({
          id,
          forceUpdate,
          productionStatus,
          disableCancel: true,
        }),
      );

      const response = yield all(multipleValidateIds);
      let saveResults = response.map(obj => obj.data);
      if (saveResults.some(obj => !obj.state)) {
        yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
      } else {
        yield put(advancedSearchActions.getSearchResults(postParam));
        yield put(sourceDocumentAction.resetStagedRecordData());
        let message = [];
        message.push(
          <p>
            Validated Ids: <b>#{`${validIds.join(', ')}`}</b>
          </p>,
        );
        if (errorIds.length)
          message.push(
            <p>
              Ids with error: <b>#{`${errorIds.join(', ')}`}</b>
            </p>,
          );
        const modalData = {
          isOpen: true,
          modalListMessage: message,
          modalType: MODAL_TYPES.CUSTOM_INFO_LIST,
        };
        yield put(globalActions.showModal(modalData));
      }
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export const watchers = [
  function* watchGetSourceDocumentList() {
    yield takeEvery(sourceDocumentAction.getSourceDocumentList, getSourceDocumentListAsync);
  },
  function* watchDeleteSourceDocumentAsync() {
    yield takeEvery(sourceDocumentAction.deleteSourceDocument, deleteSourceDocumentAsync);
  },

  function* watchSaveSourceDocumenAsync() {
    yield takeEvery(sourceDocumentAction.saveSourceDocumentDetails, saveSourceDocumentAsync);
  },

  function* watchSaveSourceDocumentsAsync() {
    yield takeEvery(sourceDocumentAction.saveUploadFiles, saveSourceDocumentsAsync);
  },

  function* watchSaveSourceDocumentDownloadAsync() {
    yield takeEvery(sourceDocumentAction.saveDownlaodFiles, saveSourceDocumentDownloadAsync);
  },

  function* watchGetSourceDocumentMasterDataAsync() {
    yield takeEvery(
      sourceDocumentAction.getSourceDocumentMasterData,
      getSourceDocumentMasterDataAsync,
    );
  },

  function* watchGetRegistryAsync() {
    yield takeEvery(sourceDocumentAction.getSourceDocumentDetails, getSourceDocumentAsync);
  },

  function* watchPublishSourceDoc() {
    yield takeEvery(sourceDocumentAction.publishSourceDocument, publishSourceDocAsync);
  },

  function* watchOutofScopeSourceDoc() {
    yield takeEvery(sourceDocumentAction.moveOutofScopeSourceDoc, moveOutofScopeSourceDocAsync);
  },

  function* watchGetSDStageRecordId() {
    yield takeEvery(sourceDocumentAction.getStagedIdSourceDoc, getStagedIdSourceDocAsync);
  },

  function* watchValidateSourceDoc() {
    yield takeEvery(sourceDocumentAction.validateSourceDoc, validateSourceDocAsync);
  },

  function* watchGetSourceDocRecordChangeFieldsList() {
    yield takeEvery(
      sourceDocumentAction.getSourceDocRecordChangeFieldsList,
      getSourceDocRecordChangeFldsListAsync,
    );
  },
  function* watchGetSourceDocumentStampingDetails() {
    yield takeEvery(
      sourceDocumentAction.getSourceDocumentStampingDetails,
      getSourceDocumentStampingDetailsAsync,
    );
  },
  function* watchGetSourceDocumentList() {
    yield takeEvery(
      sourceDocumentAction.releaseSourceDocumentUserLock,
      releaseSourceDocumentUserLockAsync,
    );
  },

  function* watchSaveSourceDocumentAsync() {
    yield takeEvery(
      sourceDocumentAction.updateSourceDocumentLockDetails,
      updateSourceDocumentLockDetailsAsync,
    );
  },
  function* watchGetSrcDocCompareDataAsync() {
    yield takeEvery(sourceDocumentAction.getSrcDocCompareData, getSrcDocCompareDataAsync);
  },
  function* watchSrcDocBulkLinking() {
    yield takeEvery(sourceDocumentAction.sourceDocBulkLinking, sourceDocBulkLinkingAsync);
  },
  function* watchSrcDocBulkValidate() {
    yield takeEvery(sourceDocumentAction.sourceDocBulkValidate, sourceDocBulkValidateAsync);
  },
];
